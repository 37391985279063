'use strict';

angular.module('app')
    .controller('PageDetailController', function (PageService, AssetService, $scope, $uibModal, notify, $timeout, $stateParams, Upload, $q, $state) {

        var page = this;
        page.modal = {};
        page.edit = {preview_image: '', active: true, id: $stateParams.id};
        page.filter = {
            'page': 1,
            'query': ''
        };

        page.get_page_detail = function () {
            if (page.edit.id) {
                PageService.get_page_id(page.edit.id).then(function success(response){
                    page.edit = response.data;
                });
            }
        };

        page.editor_option = {
            'min-height': 300,
            toolbar: [
                ['edit', ['undo', 'redo']],
                ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear']],
                ['fontclr', ['color']],
                ['alignment', ['ul', 'paragraph', 'lineheight']],
                ['table', ['table']],
                ['insert', ['link', 'picture', 'video', 'hr']],
                ['view', ['codeview']]
            ]
        };

        page.init = function () {
            page.get_page_detail();
        };

        page.init();

        page.editor_image_upload = function (files) {
            angular.forEach(files, function (file) {
                AssetService.add(page.edit.title, file, 'page').then(function (result) {
                    var image_upload_result = result.data;
                    page.editor.summernote('insertImage', image_upload_result.source, image_upload_result.title);
                });
            });
        };

        page.set_preview_image = function (element) {
            var reader = new FileReader();
            reader.onload = function (event) {
                page.edit.preview_image = event.target.result;
                $scope.$apply()
            };
            reader.readAsDataURL(element.files[0]);
        };

        page.delete = function () {
            PageService.delete(page.edit.id).then(function success() {
                $state.go('content.page_list', {}, {reload: true});
            }, function error(response) {
                notify({
                    message: response.data,
                    classes: 'alert-info',
                    templateUrl: 'app/common/notify.html'
                });
            });
        };

        page.save = function (redirect) {

            if (page.page_image){
                page.edit.image = page.page_image;
            }

            if (page.edit.id) {
                // update
                PageService.update(page.edit).then(function success() {
                    if(redirect){
                        $state.go('content.page_list', {}, {reload: true});
                    }
                }, function error(response) {
                    notify({
                        message: response.data,
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                });
            } else {
                // add
                PageService.add(page.edit).then(function success() {
                    $state.go('content.page_list', {}, {reload: true});
                }, function error(response) {
                    notify({
                        message: response.data,
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                });
            }

        };

    });
