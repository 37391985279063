'use strict';

angular.module('app')
    .factory('PromotionService', function ($http, APIURL, RefreshToken, $filter) {

        var promotion = this;
        var limit = 10;
        var params = {};

        promotion.get_list = function (filter) {
            var page = filter.page || 1;
            params.limit = limit;
            params.offset = limit*(page-1);

            return $http({
                url: APIURL + '/promotion/list/',
                method: 'GET',
                params: params
            });
        };

        promotion.form_prepare = function (detail) {
            var fd = new FormData();
            fd.append('title', detail.title);
            fd.append('description', detail.description);
            fd.append('promotion_type', detail.promotion_type);
            fd.append('promotion_condition', detail.promotion_condition);
            fd.append('discount_type', detail.discount_type);

            fd.append('discount_type_parameter_value', detail.discount_type_parameter_value);

            fd.append('time_to_use_per_user', detail.time_to_use_per_user);
            fd.append('use_with_all_product', detail.use_with_all_product);

            fd.append('use_per_order', detail.use_per_order);

            fd.append('active', detail.active);
            if(detail.start_date){
                if(detail.start_date._d){
                    fd.append('start_date', $filter('date')(detail.start_date._d, 'yyyy-MM-dd 00:00:00'));
                } else {
                    fd.append('start_date', detail.start_date);
                }
            }

            if(detail.ended_date) {
                if (detail.ended_date._d) {
                    fd.append('ended_date', $filter('date')(detail.ended_date._d, 'yyyy-MM-dd 00:00:00'));
                } else {
                    fd.append('ended_date', detail.ended_date);
                }
            }

            if(detail.promotion_type_parameter_value){
                fd.append('promotion_type_parameter_value', detail.promotion_type_parameter_value);
            }

            if(detail.promotion_condition_parameter_value){
                fd.append('promotion_condition_parameter_value', detail.promotion_condition_parameter_value);
            }

            if(detail.user) {
                angular.forEach(detail.user, function(user){
                    fd.append('user', user.id);
                });
            }

            if(detail.use_with_product_in_tag){
                fd.append('use_with_product_in_tag', detail.use_with_product_in_tag);
            }

            if(detail.image){
                fd.append('preview_image', detail.image);
            }

            if(detail.title){
                return fd;
            } else {
                return false;
            }
        };

        promotion.add = function (promotion_detail) {
            var fd = this.form_prepare(promotion_detail);

            return $http.post(APIURL + '/promotion/list/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        promotion.update = function (promotion_detail) {
            var fd = this.form_prepare(promotion_detail);

            return $http.put(APIURL + '/promotion/list/' + promotion_detail.id + '/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        promotion.delete = function (promotion_detail) {
            return $http({
                url: APIURL + '/promotion/list/' + promotion_detail.id + '/',
                method: 'DELETE'
            });
        };

        return promotion;
    });
