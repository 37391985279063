'use strict';

angular.module('app')
    .controller('BlogDetailController', function (BlogService, AssetService, $scope, $uibModal, notify, $timeout, $stateParams, Upload, $q, $state) {

        var blog = this;
        blog.modal = {};
        blog.edit = {preview_image: '', active: true, id: $stateParams.id};
        blog.filter = {
            'page': 1,
            'query': ''
        };

        blog.slugify = function (word) {
            return word.toString().toLowerCase().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-').replace(/^-+/, '').replace(/-+$/, '');
        };

        blog.slug_change = function () {
            blog.edit.slug = blog.slugify(blog.edit.slug);
        };

        blog.get_blog_category_list = function () {
            BlogService.get_blog_category().then(function success(response) {
                blog.category_list = response.data;
            });
        };

        blog.get_blog_detail = function () {
            if (blog.edit.id) {
                BlogService.get_blog_detail(blog.edit.id).then(function success(response) {
                    blog.edit = response.data;
                    angular.forEach(blog.edit.category, function (category_id, index) {
                        BlogService.get_blog_category_detail(category_id).then(function success(response) {
                            blog.edit.category[index] = response.data;
                        });
                    });
                });
            }
        };

        blog.init = function () {
            blog.get_blog_category_list();
            blog.get_blog_detail();
        };

        blog.init();

        blog.set_preview_image = function (element) {
            var reader = new FileReader();
            reader.onload = function (event) {
                blog.edit.preview_image = event.target.result;
                $scope.$apply()
            };
            reader.readAsDataURL(element.files[0]);
        };

        blog.delete = function () {
            BlogService.delete_blog(blog.edit.id).then(function success(){
                $state.go('content.blog_list', {}, {reload: true});
            });
        };

        blog.save = function (redirect) {
            if (blog.edit.id) {
                // update
                BlogService.update_blog(blog.edit).then(function success(response) {
                    if(redirect){
                        $state.go('content.blog_list', {}, {reload: true});
                    }
                }, function error(response) {
                    notify({
                        message: 'ข้อมูลไม่ครบถ้วน กรุณาตรวจสอบ',
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                });
            } else {
                // add
                BlogService.add_blog(blog.edit).then(function success(response) {
                    $state.go('content.blog_list', {}, {reload: true});
                }, function error(response) {
                    notify({
                        message: 'ข้อมูลไม่ครบถ้วน กรุณาตรวจสอบ',
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                });
            }

        };

    });
