'use strict';

angular.module('app')
    .controller('BranchListController', function (BranchService, $timeout, notify) {

        var branch = this;
        branch.detail = {};

        branch.init_value = function() {
            branch.detail = {};
            branch.adding_status = true;
            branch.delete_status = false;
            branch.editing_status = false;
        };

        branch.init = function () {

            branch.adding_status = true;
            branch.delete_status = false;
            branch.editing_status = false;

            BranchService.get_branch().then(function success(response) {
                branch.list = response.data;
            });
        };

        branch.init();

        branch.edit = function (edit_branch) {
            branch.adding_status = false;
            branch.editing_status = true;
            angular.copy(edit_branch, branch.detail);
        };

        branch.cancel = function() {
            branch.adding_status = true;
            branch.editing_status = false;
            branch.detail = {};
        };

        branch.save = function () {

            if (!branch.detail.name) {
                notify({
                        message: 'กรุณาตรวจสอบข้อมูล',
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                return false;
            }

            if (branch.detail.id) {

                // update collection
                BranchService.update(branch.detail).then(function success(response){
                    branch.update_completed = true;
                    branch.init();
                    branch.init_value();
                    $timeout(function(){
                        branch.update_completed = false;
                    }, 3000);
                });


            } else {

                // add new collection
                BranchService.add(branch.detail).then(function success(response){
                    branch.update_completed = true;
                    branch.init();
                    branch.init_value();
                    $timeout(function(){
                        branch.update_completed = false;
                    }, 3000);

                }, function error(response){
                    notify({
                        message: 'กรุณาตรวจสอบข้อมูล',
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                });

            }

        };

    });
