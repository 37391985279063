'use strict';

angular.module('app')
    .factory('ShippingService', function ($http, APIURL, RefreshToken, $filter) {

        var shipping = this;

        shipping.get_shipping_list = function () {
            return $http({
                url: APIURL + '/shipping/',
                method: 'GET'
            });
        };

        shipping.add_shipping = function(data){
            var fd = new FormData();
            angular.forEach(data, function(value, key){
                if(value){
                    fd.append(key, value);
                }
            });

            return $http.post(APIURL + '/shipping/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        shipping.update_shipping = function(data){
            var fd = new FormData();
            angular.forEach(data, function(value, key){
                if(value){
                    fd.append(key, value);
                }
            });

            return $http.put(APIURL + '/shipping/' + data.id + '/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        shipping.delete_shipping = function(data){

            return $http.delete(APIURL + '/shipping/' + data.id + '/', {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        shipping.can_delete = function(id){
            return $http({
                url: APIURL + '/shipping/can_delete/?id='+id,
                method: 'GET'
            });
        };

        return shipping;
    });
