'use strict';

angular.module('app')
    .controller('PCController', function (BranchService, notify, $timeout, $scope) {

        var pc = this;

        pc.detail = {};
        pc.branch = {list: {}};
        pc.active = true;

        pc.init_value = function() {
            pc.detail = {};
            pc.adding_status = true;
            pc.delete_status = false;
            pc.editing_status = false;
        };

        pc.init = function () {

            pc.adding_status = true;
            pc.delete_status = false;
            pc.editing_status = false;

            BranchService.get_branch().then(function success(response) {
                pc.branch.list = response.data;
                console.log(response.data)
            });

            BranchService.get_pc(pc.active).then(function (response){
                pc.list = response.data;
            });
        };

        $scope.$watchGroup(['pc.active'], function () {
            BranchService.get_pc(pc.active).then(function (response){
                pc.list = response.data;
            });
        });

        pc.init();

        pc.edit = function (edit_pc) {
            pc.adding_status = false;
            pc.editing_status = true;
            angular.copy(edit_pc, pc.detail);
            pc.detail.branch = String(pc.detail.branch[0]);
        };

        pc.cancel = function() {
            pc.adding_status = true;
            pc.editing_status = false;
            pc.detail = {};
        };

        pc.save = function () {
            console.log(pc.detail);
            if (!pc.detail.name || !pc.detail.username) {
                notify({
                        message: 'กรุณาตรวจสอบข้อมูล',
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                return false;
            }

            if (pc.detail.id) {

                // update collection
                if(pc.detail.active == false){
                    pc.detail.password = Math.random().toString(36).slice(-20);
                }
                BranchService.update_pc(pc.detail).then(function(){
                    pc.update_completed = true;
                    pc.init();
                    pc.init_value();
                    $timeout(function(){
                        pc.update_completed = false;
                    }, 3000);

                }, function(){
                    notify({
                            message: 'ไม่สามารถเพิ่มผู้ใช้ใหม่ได้',
                            classes: 'alert-info',
                            templateUrl: 'app/common/notify.html'
                        });
                    return false;
                });


            } else {

                // add new pc
                if (!pc.detail.password) {
                    notify({
                            message: 'กรุณาตรวจสอบข้อมูล',
                            classes: 'alert-info',
                            templateUrl: 'app/common/notify.html'
                        });
                    return false;
                }
                BranchService.add_pc(pc.detail).then(function(){
                    pc.update_completed = true;
                    pc.init();
                    pc.init_value();
                    $timeout(function(){
                        pc.update_completed = false;
                    }, 3000);

                }, function(){
                    notify({
                            message: 'ไม่สามารถเพิ่มผู้ใช้ใหม่ได้',
                            classes: 'alert-info',
                            templateUrl: 'app/common/notify.html'
                        });
                    return false;
                });

            }

        };


    });
