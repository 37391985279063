'use strict';

angular.module('app')
    .controller('BankListController', function (DirectPaymentService, $scope, $uibModal, notify) {

        var bank = this;
        var modalInstance;
        bank.modal = {};
        bank.edit = {};

        bank.get_bank_list = function () {
            DirectPaymentService.get_bank_list().then(function success(response) {
                bank.list = response.data;
            })
        };

        bank.init = function () {
            bank.get_bank_list();
        };

        bank.init();

        bank.set_preview_image = function (element) {
            var reader = new FileReader();
            reader.onload = function (event) {
                bank.edit.logo = event.target.result;
                $scope.$apply()
            };
            reader.readAsDataURL(element.files[0]);
        };

        bank.modal.open = function (size, bank_data) {
            angular.copy(bank_data, bank.edit);
            modalInstance = $uibModal.open({
                templateUrl: 'app/payment/bank_model.html',
                scope: $scope,
                size: size,
                backdrop: 'static'
            });
        };

        bank.modal.cancel = function () {
            bank.edit = {};
            modalInstance.close();
        };

        bank.modal.save = function () {
            if(bank.bank_image){
                bank.edit.image = bank.bank_image;
            }
            if (bank.edit.id) {
                //update
                DirectPaymentService.update_bank(bank.edit).then(function success(response) {
                    bank.get_bank_list();
                    modalInstance.close();
                }, function error(response) {
                    notify({
                        message: response.data,
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                });
            } else {
                //add
                DirectPaymentService.add_bank(bank.edit).then(function success(response) {
                    bank.get_bank_list();
                    modalInstance.close();
                }, function error(response) {
                    notify({
                        message: response.data,
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                });
            }
        };

        bank.modal.delete = function () {
            DirectPaymentService.delete_bank(bank.edit.id).then(function success(response) {
                bank.get_bank_list();
                modalInstance.close();
            }, function error(response) {
                notify({
                    message: response.data,
                    classes: 'alert-info',
                    templateUrl: 'app/common/notify.html'
                });
            });
        };
});
