'use strict';

angular.module('app')
    .controller('BranchStockController', function (BranchService, ProductService, $scope, $timeout, notify, $q) {

        var branch = this;
        branch.filter = {};
        branch.filter.selected_branch = 0;
        branch.product = {};
        branch.product.filter = {title: '', active: '', collection: '', tag: '', page: 1};
        branch.loading = true;
        branch.product_stock_in_branch = {};

        branch.get_product = function() {
            ProductService.get_product_list(branch.product).then(function success(response){
                branch.product.list = response.data;
                branch.loading = false;
            });
        };

        branch.count_item = function() {
            BranchService.count_branch_stock(branch.filter.selected_branch).then(function success(response) {
                console.log(response.data.total['total_item']);
                branch.item_count = response.data.total['total_item'];
            });
        };

        $scope.$watchGroup(['branch.product.filter.page', 'branch.product.filter.title'], function () {
            branch.loading = true;
            branch.product_stock_in_branch = [];
            branch.get_product();
        });

        $scope.$watchGroup(['branch.filter.selected_branch', 'branch.product.list'], function () {
            if(branch.filter.selected_branch != 0){
                // get stock in branch
                angular.forEach(branch.product.list.results, function(product, index){
                    BranchService.get_branch_stock(branch.filter.selected_branch, product.id).then(function success(response){
                        product.branch_stock = response.data;
                    });
                });
            }
            branch.product_stock_in_branch = [];
            branch.count_item();
        });

        BranchService.get_branch().then(function success(response){
            branch.list = response.data;
            branch.list.push({'id': 0, 'name': 'คลังกลาง'});
            console.log(branch.list);
        });

        branch.init = function() {
            branch.get_product();
        };

        branch.init();

        branch.move_init = function(product_slug) {
            branch.product_moving = product_slug;
        };

        branch.reset = function() {
            branch.product_moving = '';
        };

        branch.move = function(move_product) {
            console.log(move_product.items);
            branch.moving = true;
            branch.move_error = false;
            var init_promise = [];

            if(branch.move_to_branch == undefined) {
                branch.moving = false;
                notify({
                    message: 'กรุณาเลือกสาขาที่ต้องการโอนสินค้า',
                    classes: 'alert-info',
                    templateUrl: 'app/common/notify.html'
                });
                return false;
            }

            angular.forEach(move_product.items, function(item){
                if(item.move_stock>0 && item.move_stock != undefined){
                    init_promise.push(
                        BranchService.transit(branch.filter.selected_branch, branch.move_to_branch, branch.note, item).then(function success(results){
                            console.log('post '+ branch.move_to_branch);
                            item.move_stock = 0;
                        }, function error(){
                            notify({
                                message: 'ไม่สามารถบันทึกได้',
                                classes: 'alert-info',
                                templateUrl: 'app/common/notify.html'
                            });
                            branch.move_error = true;
                        })
                    );
                }
            });

            $q.all(init_promise).then(function () {
                $timeout(function(){
                    branch.moving = false;
                }, 1000);
                branch.move_to_branch = '';
                if(!branch.move_error){
                    notify({
                        message: 'บันทึกเรียบร้อย',
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                }
                branch.get_product();
            });

        };

        branch.get_all_branch_stock = function(product) {
            var get_all_branch = [];
            branch.product_stock_in_branch[product.slug] = [];
            angular.forEach(branch.list, function(branch_info, index){
                get_all_branch.push(
                    BranchService.get_branch_stock(branch_info.id, product.id).then(function success(response){
                        if(branch_info.id != 0 && response.data.length > 0){
                            branch.product_stock_in_branch[product.slug].push(response.data);
                        }
                    })
                );
            });
             $q.all(get_all_branch).then(function () {
                 // console.log('finish');
                 // console.log(product);
                 console.log(branch.product_stock_in_branch[product.slug]);
                 console.log(branch.product_stock_in_branch[product.slug].length);
                 if(branch.product_stock_in_branch[product.slug].length === 0){
                     branch.product_stock_in_branch[product.slug] = 'empty';
                 }
             });
        };

        branch.export_excel = function() {
            branch.is_loading_excel_file = true;
            BranchService.stock_excel(branch.filter.selected_branch).then(function success(response){
                var blob = new Blob([response.data], { type: "application/xls" });
                saveAs(blob, 'stock.xls');
                branch.is_loading_excel_file = false;
            });
        };

    });
