'use strict';

angular.module('app')
    .controller('BranchDailyOrderAddController', function (BranchService, ProductService, notify, $timeout, $q, $state) {
        var order = this;
        order.branch = {list: {}};
        order.pc = {list: {}};
        order.code = {list: {}};
        order.items = {list: {}};
        order.selected_item = {value: {}};
        order.selected_branch = '';
        order.list = [{selected_item: {value: false}}, {selected_item: {value: false}},
            {selected_item: {value: false}}, {selected_item: {value: false}}, {selected_item: {value: false}} ];

        BranchService.get_branch().then(function (response){
            order.branch.list = response.data;
        });

        BranchService.get_pc().then(function (response){
            order.pc.list = response.data;
        });

        BranchService.get_code().then(function (response){
            order.code.list = response.data;
        });

        BranchService.get_current_pc().then(function (response){
            order.pc.current = response.data;
            order.selected_branch = String(order.pc.current.branch);
            console.log(order.selected_branch);
            angular.forEach(order.pc.list, function(pc) {
               if(pc.id == order.pc.current.id) {
                   pc.selected = true;
               }
            });
        });

        ProductService.get_product_items().then(function (response){
            order.items.list = response.data;
            angular.forEach(order.items.list, function(item, index){
                var name = item.product_title +' - ('+ item.attribute_title + ')';
                item.name = name;
            });
        });

        order.add_row = function(){
            order.list.push({selected_item: {value: false}});
        };

        order.is_empty_list = function(list){
            var is_empty_list = true;
            angular.forEach(list, function(myorder, index){
                if(myorder.selected_item.value.id){
                    is_empty_list = false;
                }
            });
            return is_empty_list;
        };

        order.add_order = function() {
            order.uploading = true;

            var pc_list = [];
            if(!order.sale_date || !order.selected_branch) {
                notify({
                        message: 'กรุณาตรวจสอบข้อมูล',
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                order.uploading = false;
                return false;
            }
            angular.forEach(order.pc.list, function(pc){
                if(pc.selected == true) {
                    pc_list.push(pc.id);
                }
            });

            var promise = [];
            angular.forEach(order.list, function(myorder, index) {
                if(myorder.selected_item.value && myorder.quantity) {
                    console.log(myorder.selected_item.value);
                    var branch = order.selected_branch;
                    var item = myorder.selected_item.value.id;
                    var quantity = myorder.quantity;
                    var full_price = myorder.full_price;
                    var sale_price = myorder.sale_price;
                    var code = myorder.code;
                    var note = order.note;
                    var sale_date = order.sale_date;
                    var pc = pc_list;
                    var report_user = order.pc.current.id;
                    promise.push(
                        BranchService.add_order(branch, pc, item, quantity, code, full_price, sale_price, note, sale_date, report_user).then(
                        function success(){
                            order.update_error = false;
                            order.list.splice([index], 1);
                            if(order.is_empty_list(order.list)) {
                                $state.go('branch.daily_order');
                            };
                        }, function error() {
                            notify({
                                message: 'กรุณาตรวจสอบข้อมูล จำนวนสินค้าคงเหลืออาจมีไม่พอ สินค้าที่เพิ่มเรียบร้อยแล้วแล้วจะหายไปจากรายการ',
                                classes: 'alert-info',
                                templateUrl: 'app/common/notify.html'
                            });
                            order.uploading = false;
                            order.update_error = true;
                            return false;
                        })
                    );
                }

            });

            $q.all(promise).then(function success(){
                order.uploading = false;
                order.is_empty_list(order.list);
                if(!order.update_error && order.is_empty_list(order.list)) {
                    $state.go('branch.daily_order');
                }
            });

        };


    });