angular
    .module('app', [
        'ui.router',                    // Routing
        'oc.lazyLoad',                  // ocLazyLoad
        'ui.bootstrap',                 // Ui Bootstrap
        'pascalprecht.translate',       // Angular Translate
        'ngIdle',                       // Idle timer
        'ngSanitize',                   // ngSanitize
        'ngStorage',
        'ngFileUpload',
        'ngResource'
    ]).run(stageChangeHandle);

function stageChangeHandle($transitions, $rootScope, $localStorage) {
    // transition watch
    $transitions.onFinish({}, function (transition) {

        if (transition.$from().name != 'login') {
            // previous state
            $rootScope.previous_state = transition.$from().name;
        }

        if (transition.params().id) {
            // previous parameter
            $rootScope.previous_param_id = transition.params().id;
        }

    });

    // MemberService.
    // console.log('init');
    // $rootScope.root_user = 'test';
    // console.log($localStorage.token);
    $rootScope.root_user = $localStorage.root_user;
    $rootScope.shop_info = $localStorage.shop_info;
}
