'use strict';

angular.module('app')
    .directive('productFileModel', function ($parse) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var model = $parse(attrs.productFileModel);
                var modelSetter = model.assign;

                element.bind('change', function () {
                    scope.$apply(function () {
                        modelSetter(scope.product.detail, element[0].files[0]);
                    });
                });
            }
        };
    })
    .directive('productCollectionFileModel', function ($parse) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var model = $parse(attrs.productCollectionFileModel);
                var modelSetter = model.assign;

                element.bind('change', function () {
                    scope.$apply(function () {
                        modelSetter(scope.collection.detail, element[0].files[0]);
                    });
                });
            }
        };
    })
    .directive('blogCollectionFileModel', function ($parse) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var model = $parse(attrs.blogCollectionFileModel);
                var modelSetter = model.assign;

                element.bind('change', function () {
                    scope.$apply(function () {
                        modelSetter(scope.blog.detail, element[0].files[0]);
                    });
                });
            }
        };
    })
    .directive('blogFileModel', function ($parse) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var model = $parse(attrs.blogFileModel);
                var modelSetter = model.assign;

                element.bind('change', function () {
                    scope.$apply(function () {
                        modelSetter(scope.blog.edit, element[0].files[0]);
                    });
                });
            }
        };
    })
    .directive('fileModel', ['$parse', function ($parse) {
        console.log('filemodel');
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var model = $parse(attrs.fileModel);
                var modelSetter = model.assign;
                var controller_name = attrs.fileModel.split('_')[0];

                element.bind('change', function () {
                    scope.$apply(function () {
                        modelSetter(scope[controller_name], element[0].files[0]);
                    });
                });
            }
        };
    }]);
;
