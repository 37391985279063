'use strict';

angular.module('app')
    .directive('analyticsDuration', function (ShopInfoService, $http) {
        return {
            restrict: 'E',
            link: function (scope, element, attrs) {
                ShopInfoService.get_google_token().then(function success(response) {

                    var analytics_view_id = response.data.analytics_view;
                    var token = response.data.token;

                    var session_request_url = 'https://www.googleapis.com/analytics/v3/data/ga?ids=' + analytics_view_id + '&start-date=30daysAgo&end-date=yesterday&metrics=ga%3AavgSessionDuration&access_token=' + token;

                    var session_request_url_last_month = 'https://www.googleapis.com/analytics/v3/data/ga?ids=' + analytics_view_id + '&start-date=60daysAgo&end-date=30daysAgo&metrics=ga%3AavgSessionDuration&access_token=' + token;

                    $http.get(session_request_url).then(function success(response) {
                        scope.analytics.session_duration = response.data.rows[0][0] / 60;
                    });

                    $http.get(session_request_url_last_month).then(function success(response) {
                        scope.analytics.session_duration_last_month = response.data.rows[0][0] / 60;
                    });

                });
            }, templateUrl: 'app/directive/analytics-duration.html'
        };
    });
