'use strict';

angular.module('app')
    .controller('BranchDailyOrderController', function (BranchService, $scope) {
        var order = this;
        order.filter = {page: 1, start_date: '', end_date: '', item__product__title: '', report_user__user: ''};
        if(!$scope.root_user.is_superuser) {
            order.filter.report_user__user = $scope.root_user.id;
        }

        order.get_order = function() {
            BranchService.get_branch_order(order.filter).then(function (response){
                order.list = response.data;
                order.updating = false;
            });
        };

        order.delete = function(order_id) {

            if (confirm('ต้องการจะลบใช่หรือไม่')) {
                console.log(order_id);
                BranchService.delete_branch_order(order_id).then(function(){
                    order.get_order();
                });
            } else {
                // Do nothing!
            }
        };

        order.export_excel = function() {
            order.is_loading_excel_file = true;
            BranchService.get_branch_order_excel(order.filter).then(function success(response){
                console.log(response);
                var blob = new Blob([response.data], { type: "application/xls" });
                saveAs(blob, 'branch-order.xls');
                order.is_loading_excel_file = false;
            });
        };

        $scope.$watchGroup(['order_c.filter.page', 'order_c.filter.start_date', 'order_c.filter.end_date', 'order_c.filter.item__product__title'], function () {
            order.updating = true;
            order.get_order();
        });

    });