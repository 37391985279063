'use strict';

angular.module('app')
    .factory('BranchService', function ($http, APIURL, $filter, RefreshToken) {

        var branch = this;
        branch.is_updating = false;

        branch.get_branch = function () {
            return $http({
                url: APIURL + '/product/branch/',
                method: 'GET'
            });
        };

        branch.add = function (branch) {
            var fd = this.branch_form_prepare(branch);
            return $http.post(APIURL + '/product/branch/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        branch.update = function (branch) {
            var fd = this.branch_form_prepare(branch);
            return $http.put(APIURL + '/product/branch/' + branch.id + '/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        branch.branch_form_prepare = function (branch) {
            var fd = new FormData();
            fd.append('name', branch.name);
            fd.append('detail', branch.detail);
            fd.append('address', branch.address);
            return fd;
        };

        branch.get_branch_stock = function (branch, product) {
            return $http({
                url: APIURL + '/product/branch_stock/?branch=' + branch + '&item__product__id=' + product,
                method: 'GET'
            });
        };

        branch.count_branch_stock = function (branch) {
            return $http({
                url: APIURL + '/product/branch_stock/item_count/?branch=' + branch,
                method: 'GET'
            });
        };

        branch.transit = function (from_branch, to_branch, note, item) {
            branch.is_updating = true;
            var fd = new FormData();
            if (from_branch > 0) {
                fd.append('from_branch', from_branch);
            }
            if (to_branch > 0) {
                fd.append('to_branch', to_branch);
            }
            fd.append('note', note || '');
            fd.append('value', item.move_stock);
            fd.append('item', item.id);
            return $http.post(APIURL + '/product/item_transit/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        branch.get_item_transit_history = function (filter) {
            var limit = 50;
            var from_central_branch = '';
            var to_central_branch = '';
            var from_branch = filter.from_branch;
            var to_branch = filter.to_branch;
            var item__product__title = '';

            if (angular.isObject(filter.start_date)) {
                var start_date = $filter('date')(filter.start_date['_d'], 'yyyy-MM-dd');
            }
            if (angular.isObject(filter.end_date)) {
                var end_date = $filter('date')(filter.end_date['_d'], 'yyyy-MM-dd 23:59');
            }

            if (filter.from_branch == 'central') {
                from_central_branch = true;
                from_branch = '';
            }
            if (filter.to_branch == 'central') {
                to_central_branch = true;
                to_branch = '';
            }
            if (filter.item__product__title) {
                item__product__title = filter.item__product__title
            }

            var page = filter.page || 1;

            var params = {
                limit: limit,
                offset: limit * (page - 1),
                start_date: start_date,
                end_date: end_date,
                from_branch: from_branch,
                to_branch: to_branch,
                from_central_branch: from_central_branch,
                to_central_branch: to_central_branch,
                item__product__title: item__product__title
            };

            return $http({
                url: APIURL + '/product/item_transit_history/',
                method: 'GET',
                params: params
            });
        };

        branch.add_pc = function (pc) {
            var fd = new FormData();
            fd.append('name', pc.name);
            fd.append('phone', pc.phone);
            fd.append('address', pc.address);
            fd.append('branch', pc.branch);
            fd.append('username', pc.username);
            fd.append('password', pc.password);

            return $http.post(APIURL + '/product/product_consultant/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });

        };

        branch.update_pc = function (pc) {
            var fd = new FormData();
            fd.append('name', pc.name);
            fd.append('phone', pc.phone);
            fd.append('address', pc.address);
            fd.append('branch', pc.branch);
            fd.append('username', pc.username);
            fd.append('password', pc.password);
            fd.append('active', pc.active);

            return $http.put(APIURL + '/product/product_consultant/' + pc.id + '/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });

        };

        branch.get_pc = function (only_active_pc) {
            var params = {};
            params['active'] = only_active_pc;
            return $http({
                url: APIURL + '/product/product_consultant/',
                method: 'GET',
                params: params
            });
        };

        branch.get_current_pc = function () {
            return $http({
                url: APIURL + '/product/product_consultant/current_user/',
                method: 'GET'
            });
        };

        branch.get_code = function () {
            return $http({
                url: APIURL + '/product/code/',
                method: 'GET'
            });
        };

        branch.add_code = function (code) {
            var fd = new FormData();
            fd.append('branch', code.branch);
            fd.append('code', code.code);
            fd.append('commission_rate', code.commission_rate);
            fd.append('gp', code.gp);
            fd.append('note', code.note);

            return $http.post(APIURL + '/product/code/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        branch.update_code = function (code) {
            var fd = new FormData();
            fd.append('branch', code.branch);
            fd.append('code', code.code);
            fd.append('commission_rate', code.commission_rate);
            fd.append('gp', code.gp);
            fd.append('note', code.note);

            return $http.put(APIURL + '/product/code/' + code.id + '/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        branch.add_order = function (branch, pc_list, item, quantity, code, full_price, sale_price, note, sale_date, report_user) {
            var fd = new FormData();
            fd.append('branch', branch);
            fd.append('item', item);
            fd.append('quantity', quantity);
            fd.append('code', code);
            fd.append('full_price', full_price);
            fd.append('sale_price', sale_price);
            fd.append('note', note);
            fd.append('report_user', report_user);

            if (sale_date) {
                sale_date = $filter('date')(sale_date['_d'], 'yyyy-MM-dd');
                fd.append('sale_date', sale_date);
            }
            angular.forEach(pc_list, function (pc) {
                fd.append('pc', pc);
            });

            return $http.post(APIURL + '/product/branch_order/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        branch.get_branch_order = function (filter) {

            var limit = 50;

            var page = filter.page || 1;
            var start_date = '';
            var end_date = '';
            var item__product__title = '';

            if (angular.isObject(filter.start_date)) {
                start_date = $filter('date')(filter.start_date['_d'], 'yyyy-MM-dd');
            }
            if (angular.isObject(filter.end_date)) {
                end_date = $filter('date')(filter.end_date['_d'], 'yyyy-MM-dd 23:59');
            }
            if (filter.item__product__title) {
                item__product__title = filter.item__product__title;
            }

            var params = {
                limit: limit,
                offset: limit * (page - 1),
                start_date: start_date,
                end_date: end_date,
                item__product__title: item__product__title,
            };

            if (filter.report_user__user) {
                params.report_user__user = filter.report_user__user;
            }

            return $http({
                url: APIURL + '/product/branch_order/',
                method: 'GET',
                params: params
            });
        };

        branch.get_branch_order_excel = function (filter) {

            var start_date = '';
            var end_date = '';
            var item__product__title = '';

            if (angular.isObject(filter.start_date)) {
                start_date = $filter('date')(filter.start_date['_d'], 'yyyy-MM-dd');
            }
            if (angular.isObject(filter.end_date)) {
                end_date = $filter('date')(filter.end_date['_d'], 'yyyy-MM-dd 23:59');
            }
            if (filter.item__product__title) {
                item__product__title = filter.item__product__title;
            }

            var params = {
                start_date: start_date,
                end_date: end_date,
                item__product__title: item__product__title,
                format_type: 'excel'
            };

            if (filter.report_user__user) {
                params.report_user__user = filter.report_user__user;
            }

            return $http({
                url: APIURL + '/product/branch_order/',
                method: 'GET',
                params: params,
                headers: {
                    'Content-Type': "application/xls",
                },
                responseType: 'arraybuffer'
            });
        };

        branch.delete_branch_order = function (order_id) {
            return $http({
                url: APIURL + '/product/branch_order/' + order_id + '/',
                method: 'DELETE'
            });
        };

        branch.get_commission = function (filter) {
            var limit = 50;

            if (angular.isObject(filter.start_date)) {
                var start_date = $filter('date')(filter.start_date['_d'], 'yyyy-MM-dd');
            }
            if (angular.isObject(filter.end_date)) {
                var end_date = $filter('date')(filter.end_date['_d'], 'yyyy-MM-dd 23:59');
            }

            var page = filter.page || 1;

            var params = {
                limit: limit,
                offset: limit * (page - 1),
                start_date: start_date,
                end_date: end_date,
                pc: filter.pc
            };

            if (filter.pc__user) {
                params.pc__user = filter.pc__user;
            }

            return $http({
                url: APIURL + '/product/commission/',
                method: 'GET',
                params: params
            });
        };

        branch.get_commission_excel = function (filter) {

            var start_date = '';
            var end_date = '';

            if (angular.isObject(filter.start_date)) {
                start_date = $filter('date')(filter.start_date['_d'], 'yyyy-MM-dd');
            }
            if (angular.isObject(filter.end_date)) {
                end_date = $filter('date')(filter.end_date['_d'], 'yyyy-MM-dd 23:59');
            }

            var params = {
                start_date: start_date,
                end_date: end_date,
                pc: filter.pc,
                format_type: 'excel'
            };

            if (filter.pc__user) {
                params.pc__user = filter.pc__user;
            }

            return $http({
                url: APIURL + '/product/commission/',
                method: 'GET',
                params: params,
                headers: {
                    'Content-Type': "application/xls",
                },
                responseType: 'arraybuffer'
            });
        };

        branch.income = function (filter) {
            var limit = 50;
            var order__item__product__title = '';

            if (angular.isObject(filter.start_date)) {
                var start_date = $filter('date')(filter.start_date['_d'], 'yyyy-MM-dd');
            }
            if (angular.isObject(filter.end_date)) {
                var end_date = $filter('date')(filter.end_date['_d'], 'yyyy-MM-dd 23:59');
            }

            if (filter.order__item__product__title) {
                order__item__product__title = filter.order__item__product__title;
            }

            var page = filter.page || 1;

            var params = {
                limit: limit,
                offset: limit * (page - 1),
                start_date: start_date,
                end_date: end_date,
                branch: filter.branch,
                order__item__product__title: order__item__product__title
            };

            return $http({
                url: APIURL + '/product/branch_income/',
                method: 'GET',
                params: params
            });
        };

        branch.income_summary = function (filter) {
            var limit = 10000;
            var order__item__product__title = '';

            if (angular.isObject(filter.start_date)) {
                var start_date = $filter('date')(filter.start_date['_d'], 'yyyy-MM-dd');
            }
            if (angular.isObject(filter.end_date)) {
                var end_date = $filter('date')(filter.end_date['_d'], 'yyyy-MM-dd 23:59');
            }

            if (filter.order__item__product__title) {
                order__item__product__title = filter.order__item__product__title;
            }

            var page = filter.page || 1;

            var params = {
                limit: limit,
                offset: limit * (page - 1),
                start_date: start_date,
                end_date: end_date,
                branch: filter.branch,
                order__item__product__title: order__item__product__title
            };

            return $http({
                url: APIURL + '/product/branch_income/summary/',
                method: 'GET',
                params: params
            });
        };

        branch.income_excel = function (filter) {

            var start_date = '';
            var end_date = '';
            var order__item__product__title = '';

            if (angular.isObject(filter.start_date)) {
                start_date = $filter('date')(filter.start_date['_d'], 'yyyy-MM-dd');
            }
            if (angular.isObject(filter.end_date)) {
                end_date = $filter('date')(filter.end_date['_d'], 'yyyy-MM-dd 23:59');
            }

            if (filter.order__item__product__title) {
                order__item__product__title = filter.order__item__product__title;
            }

            var params = {
                start_date: start_date,
                end_date: end_date,
                branch: filter.branch,
                order__item__product__title: order__item__product__title,
                format_type: 'excel'
            };

            if (filter.pc__user) {
                params.pc__user = filter.pc__user;
            }

            return $http({
                url: APIURL + '/product/branch_income/',
                method: 'GET',
                params: params,
                headers: {
                    'Content-Type': "application/xls",
                },
                responseType: 'arraybuffer'
            });
        };

        branch.stock_excel = function (branch_id) {

            return $http({
                url: APIURL + '/product/detail/?format_type=excel&branch='+branch_id,
                method: 'GET',
                headers: {
                    'Content-Type': "application/xls",
                },
                responseType: 'arraybuffer'
            });
        };

        return branch;

    });
