'use strict';

angular.module('app')
    .controller('OrderListController', function (OrderService, $scope, $uibModal, $interval, $q, $timeout) {
        var order = this;
        order.auto_refresh = true;
        // list of selected order
        order.selected_order_list = [];
        order.filter = [];
        order.filter.updated_from = "";
        order.filter.added_from = "";
        order.filter.page = 1;
        order.modal = [];
        order.upload_modal = [];
        order.order_excel_file = '';
        order.list = false;
        var orderStatusModalInstance;
        var orderUploadModalInstance;

        order.get_order_list = function (callback) {
            OrderService.get_order_list(order.filter).then(function (response) {
                if (order.list.results) {
                    angular.forEach(order.list.results, function (current_value, current_key) {
                        angular.forEach(response.data.results, function (value, key) {
                            if(value.id == current_value.id && value.status == 'payment_review' && current_value.status != 'payment_review') {
                                $scope.sounds.play();
                            }
                            if(value.id == current_value.id && value.status == 'paid' && current_value.status != 'paid') {
                                $scope.sounds.play();
                            }
                        });
                    });
                }
                order.list = response.data;
                if(callback){
                    callback();
                }
            });
        };

        order.get_order_list_polling = function () {
            if (order.auto_refresh) {
                order.get_order_list();
            }
        };

        order.get_bank_list = function () {
            order.filter.bank = '';
            OrderService.get_bank_list().then(function (response) {
                order.bank_list = response.data.banks;
            })
        };

        order.init = function () {

            order.get_order_list();
            $scope.$watchGroup(['order.filter.order_id', 'order.filter.status', 'order.filter.customer_name',
                'order.filter.min_total_price', 'order.filter.updated_from', 'order.filter.added_from', 'order.filter.bank', 'order.filter.payment_type'], function () {
                order.get_order_list();
            });

            // polling and destroy scope
            var promise = $interval(order.get_order_list_polling, 10000);
            $scope.$on('$destroy', function(){
                if (angular.isDefined(promise)) {
                    $interval.cancel(promise);
                    promise = undefined;
                }
            });

            order.get_bank_list();

        };

        order.init();

        order.create_selected_order_list = function (update_order_list, select_status) {

            angular.forEach(update_order_list, function (order_detail) {
                if (select_status == true) {
                    // add order to list
                    if (order.selected_order_list.indexOf(order_detail.id) < 0) {
                        order.selected_order_list.push(order_detail.id);
                    }
                } else {
                    // remove order from list
                    var remove_index = order.selected_order_list.indexOf(order_detail.id);
                    if (remove_index >= 0) {
                        order.selected_order_list.splice(remove_index, 1);
                    }
                }

            });

            if (order.selected_order_list.length == 0) {
                order.auto_refresh = true;
            }

        };

        order.select_order = function (order_detail, select_status) {
            order.auto_refresh = false;
            order.create_selected_order_list([order_detail], select_status);
        };

        order.select_all_display_order = function () {
            order.create_selected_order_list(order.list.results, true);
            angular.forEach(order.list.results, function (order_detail, index) {
                order.input_order_selected_value[order_detail.order_identity] = true;
            });
        };

        order.empty_selected_order_list = function () {
            order.auto_refresh = true;
            order.input_order_selected_value = [];
            order.selected_order_list = [];
        };

        order.change_ordering = function (order_by) {
            order.list = [];
            if (order.filter.ordering == order_by) {
                if (order_by.split('-').length > 1) {
                    order.filter.ordering = order_by.split('-')[1];
                } else {
                    order.filter.ordering = '-' + order_by;
                }
            } else {
                order.filter.ordering = order_by;
            }
            order.get_order_list();
            console.log(order.filter.ordering);
        };

        order.pageChanged = function () {
            order.filter.page = order.page;
            order.get_order_list();
        };


        order.modal.open = function (size, order_detail) {
            order.saving = false;
            order.open_modal_with_order = angular.copy(order_detail);
            orderStatusModalInstance = $uibModal.open({
                templateUrl: 'app/order/modal_change_status.html',
                scope: $scope,
                size: size
            });
        };

        order.modal.cancel = function() {
            orderStatusModalInstance.close();
        };

        order.modal.save = function(update_order) {
            order.saving = true;
            if(update_order.status == 'completed' && !update_order.shipping_code) {
                alert('กรุณากรอกเลข Tracking');

            } else {
                OrderService.update_order_status(update_order).then(function success(){
                    order.get_order_list(orderStatusModalInstance.close);
                    // orderStatusModalInstance.close();
                });
            }
        };

        order.modal.change_status = function(status){
            if(status=='completed'){
                order.open_modal_with_order.tracking_code = true;
            } else {
                order.open_modal_with_order.tracking_code = false;
            }
        };

        order.change_to_paid = function(update_order) {
            update_order.status = 'paid';
            order.saving = true;
            OrderService.update_order_status(update_order).then(function success(){
                order.get_order_list();
            });
        };

        order.export_excel = function(){
            order.is_loading_excel_file = true;
            OrderService.get_excel_file(order.selected_order_list.join()).then(function success(response){
                var blob = new Blob([response.data], { type: "application/xls" });
                saveAs(blob, 'order.xls');
                order.is_loading_excel_file = false;
            });
        };

        order.export_pdf = function(order_id){
            if(!order_id) {
                if (!order.is_loading_pdf_file) {
                    order.is_loading_pdf_file = true;
                } else {
                    return false;
                }
                OrderService.get_pdf_file(order.selected_order_list.join()).then(function success(response) {
                    var blob = new Blob([response.data], {type: "application/pdf"});
                    saveAs(blob, 'order.pdf');
                    order.is_loading_pdf_file = false;
                });
            } else {
                OrderService.get_pdf_file(order_id).then(function success(response) {
                    var blob = new Blob([response.data], {type: "application/pdf"});
                    saveAs(blob, 'order.pdf');
                    order.is_loading_pdf_file = false;
                });
            }

        };

        order.upload_modal.open = function (size, order_detail) {
            order.open_modal_with_order = angular.copy(order_detail);
            orderUploadModalInstance = $uibModal.open({
                templateUrl: 'app/order/modal_upload_status.html',
                scope: $scope,
                size: size
            });
        };

        order.upload_order_file = function(){
            order.updating_excel = true;
            OrderService.upload_excel_file(order.order_excel_file).then(function success(){
                orderUploadModalInstance.close();
                order.updating_excel = false;
                order.get_order_list();
            });
        };

        order.get_product_status = function(order_detail) {
            var ready_to_ship = true;
            angular.forEach(order_detail.order_products, function (product) {
                if(!moment(product.product_shipping_date).isBefore(moment())) {
                    ready_to_ship = false;
                }
            });
            return ready_to_ship;
        };

        order.change_select_order_to = function(status) {
            var status_text = '';
            if(status == 'paid') {
                status_text = 'จ่ายแล้ว'
            } else if(status == 'processing') {
                status_text = 'รอส่ง';
            } else if(status == 'completed') {
                status_text = 'ส่งแล้ว';
            }
            if (confirm('ต้องการจะเปลี่ยนสถานะเป็น "'+status_text+'" ใช่หรือไม่')) {
                order.changing_bulk_order = true;
                var promises = [];
                angular.forEach(order.selected_order_list, function (id) {
                    promises.push(OrderService.update_status_by_order_id(id, status).then(function success() {
                    }));
                });

                $q.all(promises).then(function () {
                    order.init();
                    $timeout(function () {
                        order.changing_bulk_order = false;
                    }, 1000);
                    console.log('monitor');
                });
            }
        };

    });
