'use strict';

angular.module('app')
    .controller('BlockListController', function (ProductService, AssetService, BlockService, $scope, notify, $filter, $uibModal, $stateParams, Upload, $q, $state) {

        var block = this;
        var modalInstance;
        block.groups = [];
        block.list = [];
        block.status_update_block_ordering = {active: false, updating: false};
        block.modal = {};
        block.modal_block = {};
        block.edit = {text: ''};
        block.edit_block = {text: ''};

        block.sortableOptions = {
            connectWith: ".connectList"
        };

        /* --------------------------------- */
        /* ----- Block Group Command ------- */
        /* --------------------------------- */

        block.modal.open_group_edit = function (group) {
            block.edit_group = {};
            if (group) {
                block.edit_group = {
                    id: group.id,
                    title: group.title,
                    description: group.description,
                    block: group.block,
                    slug: group.slug
                }
            }
            modalInstance = $uibModal.open({
                templateUrl: 'app/block/modal_group_edit.html',
                scope: $scope
            });
        };

        block.modal.cancel = function () {
            modalInstance.close();
        };

        block.modal.save = function () {
            if (block.edit_group.id) {
                // update
                BlockService.update_block_group(block.edit_group).then(function success() {
                    block.get_block_group();
                    modalInstance.close();
                });
            } else {
                // add
                BlockService.add_group(block.edit_group).then(function success() {
                    block.get_block_group();
                    modalInstance.close();
                });
            }
        };

        block.modal.delete = function () {
            if (block.edit_group.block.length == 0) {
                // update
                BlockService.delete_block_group(block.edit_group.id).then(function success() {
                    block.get_block_group();
                    modalInstance.close();
                });
            } else {
                notify({
                    message: $filter('translate')("can't delete this group, please remove all item in group before delete"),
                    classes: 'alert-info',
                    templateUrl: 'app/common/notify.html'
                });
            }
        };

        block.get_block_group = function () {
            BlockService.get_groups().then(function (response) {
                block.groups = response.data;

                block.status_update_block_ordering = {active: false, updating: false};

                // update block ordering
                $scope.$watch('block.groups', function (newValue, oldValue) {
                    if (newValue != oldValue && newValue && oldValue) {
                        block.status_update_block_ordering.active = true;
                    }
                }, true);
            });
        };

        block.update_block_ordering = function () {
            block.status_update_block_ordering.updating = true;

            angular.forEach(block.groups, function (group) {
                var counter = group.block.length;
                angular.forEach(group.block, function (the_block) {
                    BlockService.update(group.id, the_block, counter).then(function success() {
                        block.status_update_block_ordering = {active: false, updating: false};
                    }, function error(response) {
                        notify({
                            message: $filter('translate')(response.data),
                            classes: 'alert-info',
                            templateUrl: 'app/common/notify.html'
                        });
                    });
                    counter = counter - 1;
                });
            });
        };

        block.add_block_cancel = function (group) {
            group.edit_status = false;
            block.edit = {};
        };

        /* --------------------------------- */
        /* -------- Block Command ---------- */
        /* --------------------------------- */
        block.set_preview_image = function (element) {
            var reader = new FileReader();
            reader.onload = function (event) {
                block.edit.preview_image = event.target.result;
                $scope.$apply()
            };
            reader.readAsDataURL(element.files[0]);
        };

        block.delete = function (group, edit_block) {
            console.log(edit_block);
            BlockService.delete(edit_block.id).then(function success(response) {
                group.edit_status = false;
                block.get_block_group();
            });
        };

        block.save = function (group) {

        };


        /* --- block model --- */
        block.modal_block.open_block_edit = function (group, edit_block) {
            if(edit_block) {
                // edit
                block.edit.text = '';
                group.edit_status = true;
                block.edit = edit_block;
                block.edit_group = group;
            } else {
                // add
                block.edit = {};
                block.edit_group = group;
            }

            modalInstance = $uibModal.open({
                templateUrl: 'app/block/modal_block_edit.html',
                scope: $scope,
                size: 'lg'
            });
        };

        block.modal_block.cancel = function () {
            modalInstance.close();
            block.edit = {};
        };

        block.modal_block.save = function () {

            if(block.block_image){
                block.edit.image = block.block_image;
            }

            if(block.block_file_upload) {
                block.edit.file_upload = block.block_file_upload;
            }

            if (block.edit.id) {
                // update
                BlockService.update(block.edit_group.id, block.edit).then(function success(response) {
                    block.edit = {};
                    block.block_image = null;
                    block.block_file_upload = null;
                    modalInstance.close();
                    block.get_block_group();
                }, function error(response) {
                    block.edit = {};
                    block.block_image = null;
                    block.block_file_upload = null;
                    notify({
                        message: response.data.title,
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                });
            } else {
                // add
                BlockService.add(block.edit_group.id, block.edit).then(function success(response) {
                    block.edit = {};
                    block.block_image = null;
                    block.block_file_upload = null;
                    modalInstance.close();
                    block.get_block_group();
                }, function error(response) {
                    block.edit = {};
                    block.block_image = null;
                    block.block_file_upload = null;
                    notify({
                        message: response.data.title,
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                });
            }
        };

        block.init = function () {
            block.get_block_group();
        };

        block.init();

    });
