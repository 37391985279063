'use strict';

angular.module('app')
    .factory('PaypalPaymentService', function ($http, APIURL, RefreshToken, $filter) {

        var paypal = this;

        paypal.get_config = function () {
            return $http({
                url: APIURL + '/payment/paypal_config/',
                method: 'GET'
            });
        };

        paypal.form_prepare = function (detail) {
            var fd = new FormData();
            fd.append('user_api', detail.user_api);
            fd.append('password_api', detail.password_api);
            fd.append('signature', detail.signature);
            fd.append('sandbox_mode', detail.sandbox_mode);
            fd.append('shop_name', detail.shop_name);
            fd.append('currency_code', detail.currency_code);
            fd.append('paypal_mode', detail.paypal_mode);
            fd.append('active', detail.active);

            if(detail.image){
                fd.append('shop_logo', detail.image);
            }
            return fd;
        };

        paypal.update_config = function (detail) {
            var fd = this.form_prepare(detail);

            return $http.put(APIURL + '/payment/paypal_config/'+detail.id+'/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        paypal.add_config = function (detail) {
            var fd = this.form_prepare(detail);

            return $http.post(APIURL + '/payment/paypal_config/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        return paypal;
    });
