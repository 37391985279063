'use strict';

angular.module('app')
    .factory('AuthenticationService', function($http, APIURL){

        var authen = this;

        authen.login = function(username, password){
            return $http({
                url: APIURL+'/api-token-auth/',
                method: 'POST',
                data: {
                    username: username,
                    password: password
                }
            });
        };

        authen.refresh = function(token){
            return $http({
                url: APIURL+'/api-token-refresh/',
                method: 'POST',
                data: {
                    token: token
                }
            });
        };

        return authen;
    });
