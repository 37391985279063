'use strict';

angular.module('app')
    .factory('DirectPaymentService', function ($http, APIURL, RefreshToken, $filter) {

        var direct = this;

        direct.get_bank_list = function () {
            return $http({
                url: APIURL + '/payment/bank/',
                method: 'GET'
            });
        };

        direct.bank_form_prepare = function (detail) {
            var fd = new FormData();
            fd.append('name', detail.name);
            fd.append('account_number', detail.account_number);
            fd.append('owner', detail.owner);
            fd.append('branch', detail.branch);

            if(detail.image){
                fd.append('logo', detail.image);
            }

            if(detail.name){
                return fd;
            } else {
                return false;
            }
        };

        direct.update_bank = function (detail) {
            var fd = this.bank_form_prepare(detail);

            return $http.put(APIURL + '/payment/bank/'+detail.id+'/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        direct.add_bank = function (detail) {
            var fd = this.bank_form_prepare(detail);

            return $http.post(APIURL + '/payment/bank/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        direct.delete_bank = function (id) {
            return $http({
                url: APIURL + '/payment/bank/' + id + '/',
                method: 'DELETE'
            });
        };

        return direct;
    });
