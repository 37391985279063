'use strict';

angular.module('app')
    .factory('RefreshToken', function($http, APIURL, $localStorage, $rootScope){

        function urlBase64Decode(str) {
            var output = str.replace(/-/g, '+').replace(/_/g, '/');
            return decodeURIComponent(escape(window.atob(output)));
        }

        $rootScope.$on('requestTrigger', function(){
            var date = new Date();
            var token = $localStorage.token;

            if (token) {
                var parts = token.split('.');
                var payload = JSON.parse(urlBase64Decode(parts[1]));
                var tokenExpire = payload['exp'];
                var nowTimeStamp = Math.ceil(date.valueOf()/1000);

                if( (nowTimeStamp > tokenExpire - (60*10)) && !$rootScope.isRefreshingToken){
                    $rootScope.isRefreshingToken = true;
                    $http({
                        url: APIURL+'/api-token-refresh/',
                        method: 'POST',
                        data: {
                            token: token
                        }
                    }).then(function success(response){
                        $localStorage.token = response.data.token;
                        $rootScope.isRefreshingToken = false;
                    }, function error(){
                        $rootScope.isRefreshingToken = false;
                    });
                }
            }

        });

        return this;

    });
