'use strict';

angular.module('app')
    .controller('ProductColorListController', function ( RefreshToken, ProductColorService, $scope, $timeout, notify) {

        var product = this;
        product.adding_status = true;
        product.update_completed = false;
        product.edit = {'color_image_source': '', 'preview_image': ''};

        product.init = function() {
            ProductColorService.get_color_list().then(function success(response){
                product.colors = response.data;
            });
        };

        product.init();

        product.edit_color = function(color) {
            product.edit = {};
            product.adding_status = false;
            product.editing_status = true;
            product.edit = color;
        };

        product.cancel = function() {
            product.edit = {};
            product.adding_status = true;
            product.editing_status = false;
        };

        product.set_preview_image = function(element) {
            var reader = new FileReader();
            reader.onload = function (event) {
                product.edit.color_image_source = event.target.result;
                $scope.$apply()
            };
            reader.readAsDataURL(element.files[0]);

        };

        product.delete = function(color){
            ProductColorService.count_product(color.id).then(function success(response) {
                 var product_count = response.data;
                 if(product_count) {
                     // remove color first
                     notify({
                        message: 'ไม่สามารถลบได้เพราะมีสินค้าที่ใช้สีนี้อยู่',
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                 } else {
                     ProductColorService.delete(color.id).then(function() {
                         product.init();
                     });
                 }
            });
        };

        product.save = function() {
            product.edit.preview_image = product.product_image;

            if(product.edit.id) {
                // update
                ProductColorService.update(product.edit).then(function success() {
                    product.update_completed = true;
                    product.adding_status = true;
                    product.editing_status = false;
                    product.edit = {};

                    product.init();

                    $timeout(function(){
                        product.update_completed = false;
                    }, 3000);
                });
            } else {
                // add
                ProductColorService.add(product.edit).then(function success(){
                    product.update_completed = true;
                    product.adding_status = true;
                    product.editing_status = false;
                    product.edit = {};

                    product.init();

                    $timeout(function(){
                        product.update_completed = false;
                    }, 3000);
                });
            }


        };

    });
