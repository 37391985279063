'use strict';

angular.module('app')
    .controller('ShopInfoController', function (ShopInfoService) {

        var shop_info = this;
        shop_info.is_updating = false;

        shop_info.get_shop_info = function () {
            ShopInfoService.get_shop_info().then(function (responses) {
                shop_info.data = responses.data[0];
            });
        };

        shop_info.init = function () {
            shop_info.get_shop_info();
        };

        shop_info.init();

        shop_info.save = function (form) {
            shop_info.is_updating = true;
            delete shop_info.data['shop_logo'];
            ShopInfoService.update_shop_info(shop_info.data).then(function success(responses) {
                shop_info.is_updating = false;
                form.$setPristine();
            });
        };

    });
