'use strict';

angular.module('app')
    .controller('PromotionListController', function (PromotionService, ProductService, MemberService, $uibModal, $scope, notify, $filter) {

        var promotion = this;
        var modalInstance;
        promotion.modal = {};
        promotion.edit = {};
        promotion.edit.promotion_type = false;
        promotion.edit.time_to_use_per_user = 1;
        promotion.filter = {
            'page': 1,
            'query': ''
        };
        // promotion.conditions = ['no_condition', 'total_order_price', 'total_buy_same_product'];
        promotion.member_list = [];
        promotion.conditions = ['no_condition', 'total_order_price'];

        $scope.$watchGroup(['promotion.filter.page'], function () {
            promotion.get_list();
        });

        promotion.set_promotion_discount_type = function () {
            if (promotion.edit.promotion_condition == 'total_buy_same_product') {
                promotion.discount_type = ['number_of_product'];
            } else {
                promotion.discount_type = ['percent', 'money'];
                // if (promotion.edit.discount_type == 'number_of_product') {
                //     promotion.edit.discount_type = 'money';
                // }
            }
        };

        promotion.get_list = function (callback) {
            PromotionService.get_list(promotion.filter).then(function (results) {
                promotion.list = results.data;
                if (callback) {
                    callback();
                }
            });
        };

        promotion.get_product_tag = function () {
            ProductService.get_product_tags().then(function (results) {
                promotion.product_tag_list = results.data;
            });
        };

        promotion.get_all_member = function () {
            // MemberService.get_all_member().then(function (results) {
            //     promotion.member_list = results.data;
            // });
        };


        promotion.init = function () {
            promotion.get_list();
            promotion.set_promotion_discount_type();
            promotion.get_product_tag();
            promotion.get_all_member();
        };

        promotion.init();

        promotion.modal.open = function (size, promotion_data) {
            if (promotion_data) {
                // edit
                angular.copy(promotion_data, promotion.edit);
                if (promotion_data.user.length == 0) {
                    promotion.edit.use_with_all_member = true;
                } else {
                    promotion.edit.use_with_all_member = false;

                    promotion.member_list = [];
                    angular.forEach(promotion.edit.user, function (user, user_key) {
                        // angular.forEach(promotion.member_list, function (member, key) {
                        //     if (user == member.id) {
                        //         promotion.edit.user[user_key] = member;
                        //     }
                        // });
                      MemberService.get_member_detail(user).then(function (results) {
                          promotion.member_list.push(results.data);
                      });

                    });
                }
            } else {
                // add new
                promotion.edit = {
                    'active': true,
                    'promotion_type': 'discount_cart',
                    'use_with_all_product': true,
                    'use_with_all_member': true,
                    'use_per_order': true,
                    'time_to_use_per_user': 1
                }
            }
            modalInstance = $uibModal.open({
                templateUrl: 'app/promotion/modal_promotion.html',
                scope: $scope,
                size: size,
                backdrop: 'static'
            });
        };

        promotion.modal.cancel = function () {
            promotion.edit = {};
            modalInstance.close();
        };

        promotion.modal.save = function () {
            if (promotion.promotion_image) {
                promotion.edit.image = promotion.promotion_image;
            }
            if (!promotion.edit.time_to_use_per_user) {
                promotion.edit.time_to_use_per_user = '';
            }
            if (promotion.edit.id) {
                console.log(promotion);
                promotion.edit.user = promotion.member_list;
                PromotionService.update(promotion.edit).then(function success(results) {
                    promotion.get_list(modalInstance.close);
                }, function(){
                    notify({
                        message: $filter('translate')('กรุณาตรวจสอบข้อมูล'),
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                });
            } else {
                PromotionService.add(promotion.edit).then(function success(results) {
                    promotion.get_list(modalInstance.close);
                }, function error (){
                    notify({
                        message: $filter('translate')('กรุณาตรวจสอบข้อมูล'),
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                });
            }
        };

        promotion.modal.delete = function () {
            if (promotion.edit.order_count > 0) {
                console.log('can not delete');
                notify({
                    message: $filter('translate')('can not delete a promotion that has already been used'),
                    classes: 'alert-info',
                    templateUrl: 'app/common/notify.html'
                });
            } else {
                PromotionService.delete(promotion.edit).then(function success() {
                    promotion.get_list();
                    promotion.edit = {};
                    modalInstance.close();
                });
            }

        };

        promotion.condition_change = function () {
            promotion.set_promotion_discount_type();
        };

        promotion.set_preview_image = function (element) {
            var reader = new FileReader();
            reader.onload = function (event) {
                promotion.edit.preview_image = event.target.result;
                $scope.$apply()
            };
            reader.readAsDataURL(element.files[0]);
        };

        promotion.member = "";
        promotion.add_member = function () {
          var filter = {
            query: promotion.member
          }
          if(promotion.member !== "") {
              MemberService.get_member_search(filter).then(function success(result) {
                console.log(result.data.results);
                  var member = result.data.results[0];
                  promotion.member_list.push(member);
                  console.log(member.id);
                  console.log(promotion.edit.user);
                  promotion.edit.user.push(member.id);
                  console.log(promotion.edit.user);
                  promotion.member = "";
              });
          }
        }
        promotion.delete_member = function (delete_member) {
            promotion.member_list.map(function(member, index){
                if(member.id === delete_member.id) {
                    promotion.member_list.splice(index, 1);
                }
            })
        }


    });
