'use strict';

angular.module('app')
    .factory('PageService', function ($http, APIURL, RefreshToken, $filter) {

        var page = this;

        page.get_list = function () {
            return $http({
                url: APIURL + '/page/list/',
                method: 'GET'
            });
        };

        page.get_page_id = function (id) {
            return $http({
                url: APIURL + '/page/list/'+ id + '/',
                method: 'GET'
            });
        };

        /* --------------------------------- */
        /* --------- page command ---------- */
        /* --------------------------------- */
        page.form_prepare = function (detail) {
            var fd = new FormData();
            fd.append('title', detail.title);
            fd.append('slug', detail.slug);
            fd.append('short_description', detail.short_description);
            fd.append('content', detail.content);
            fd.append('active', detail.active);

            if(detail.image){
                fd.append('preview_image', detail.image);
            }

            if(detail.title){
                return fd;
            } else {
                return false;
            }
        };

        page.add = function (detail) {
            var fd = this.form_prepare(detail);

            return $http.post(APIURL + '/page/list/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        page.update = function (detail) {
            var fd = this.form_prepare(detail);

            return $http.put(APIURL + '/page/list/' + detail.id + '/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        page.delete = function (id) {
            return $http({
                url: APIURL + '/page/list/' + id + '/',
                method: 'DELETE'
            });
        };

        return page;
    });
