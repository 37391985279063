'use strict';

angular.module('app')
    .controller('BranchTransitController', function (BranchService, $scope) {

        var branch = this;
        branch.transit = {};
        branch.filter = {start_date: '', end_date: '', page: 1, item__product__title: ''};
        branch.loading = false;

        $scope.$watchGroup(['branch.filter.start_date', 'branch.filter.end_date', 'branch.filter.page', 'branch.filter.from_branch', 'branch.filter.to_branch', 'branch.filter.item__product__title'], function () {
            branch.get_transit_history();
        });

        BranchService.get_branch().then(function success(response){
            branch.list = response.data;
            // branch.list.push({'id': 0, 'name': 'คลังกลาง'});
        });

        branch.get_transit_history = function() {
            branch.loading = true;
            BranchService.get_item_transit_history(branch.filter).then(function(response){
                branch.transit.list = response.data;
                branch.loading = false;
            });
        };

        branch.init = function() {
            branch.get_transit_history();
        };

        branch.init();

    });
