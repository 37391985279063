'use strict';

angular.module('app')
    .controller('ProductSortController', function (ProductService, AssetService, $scope, $timeout, $stateParams, Upload, $q, notify, $state) {

        var product = this;

        product.get_product_list = function () {
            ProductService.get_product_list_all().then(function success(response) {
                product.list = response.data;
            });
        };

        product.init = function () {
            product.get_product_list();
        };

        product.init();

        product.create_new_list = function(product_list){
            product.change_list = [];
            angular.forEach(product_list, function(item){
                item.parent = '';
                product.change_list.push(item);
                if(angular.isDefined(item.nodes)){
                    angular.forEach(item.nodes, function(sub_item){
                        sub_item.parent = item.id;
                        product.change_list.push(sub_item);
                    });
                }
            });
        };

        product.change_order = function(){
            var product_sort_id_list = [];
            product.updating = true;
            product.create_new_list(product.list);
            console.log(product.change_list);
            angular.forEach(product.change_list, function(item, index){
                product_sort_id_list.push(item.id);
            });

            ProductService.update_product_sort(product_sort_id_list).then(function(response){
                product.ordering_editing = false;
                product.updating = false;
            });

        };

        $scope.$watch('product.list', function(newValue, oldValue){
            if(newValue != oldValue && newValue && oldValue){
                product.ordering_editing = true;
            }
        }, true);

    });
