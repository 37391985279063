'use strict';

angular.module('app').directive('pkAudio', function () {
    return {
        restrict: 'A',
        scope: {
            pkAudio: '='
        },
        link: function (scope, element) {
            scope.pkAudio = element[0];
        }
    };
});