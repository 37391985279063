'use strict';

angular.module('app')
    .directive('productFieldsForm', function($compile){
        return {
            restrict:"A",
            terminal:true,
            priority:1000,
            link:function(scope,element,attrs){
                element.attr('name', scope.$eval(attrs.productFieldsForm));
                element.removeAttr("product-fields-form");
                $compile(element)(scope);
            }
        }
    });
