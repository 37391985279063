'use strict';

angular.module('app')
    .controller('ProductCollectionListController', function (ProductService, AssetService, $scope, $timeout, $stateParams, Upload, $q, notify, $state) {

        var collection = this;

        collection.init_value = function() {
            collection.detail = {};
            collection.detail = {list: true, active: true, image: '', preview_image: ''};
            collection.adding_status = true;
            collection.delete_status = false;
            collection.editing_status = false;
        };

        collection.get_collection_list = function () {
            ProductService.get_product_collection().then(function success(response) {
                collection.list = [];
                collection.master_list = [];
                angular.copy(response.data, collection.master_list);

                // create list
                angular.forEach(response.data, function (item) {
                    if(!item.parent){
                        item.nodes = [];
                        collection.list.push(item);
                    }
                });

                angular.forEach(response.data, function (item) {
                    if (item.parent) {
                        angular.forEach(collection.list, function (parent) {
                            if (parent.id == item.parent) {
                                try {
                                    parent['nodes'].push(item);
                                } catch (err) {
                                    parent['nodes'] = [];
                                    parent['nodes'].push(item);
                                }
                            }
                        });
                    }
                });

            });
        };

        collection.init = function () {
            collection.init_value();
            collection.get_collection_list();
            console.log('init');
        };

        collection.init();

        collection.edit = function (edit_collection) {
            collection.adding_status = false;
            collection.editing_status = true;
            angular.copy(edit_collection, collection.detail);
        };

        collection.remove = function (edit_collection) {

            ProductService.count_product_in_collection(edit_collection.id).then(function(response){
                var has_product = response.data;

                // check collection has a child
                var has_child_collection = false;
                angular.forEach(collection.master_list, function(item){
                    if(!has_child_collection){
                        if(item.parent == edit_collection.id){
                            has_child_collection = true;
                        }
                    }
                });

                if(has_product){
                    alert('ไม่สามารถบได้เพราะสินค้าในคอลเลกชันนี้อยู่อีก ' + has_product + 'ชิ้น');
                    return false;
                } else if (has_child_collection) {
                    alert('ไม่สามารถลบได้เพราะมีคอลเลกชันอื่นๆภายในคอลเลกชันนี้อยู่');
                    return false;
                } else {
                    ProductService.delete_collection(edit_collection.id).then(function(){
                        collection.get_collection_list();
                    });
                }
            });

        };

        collection.create_new_list = function(collection_list){
            collection.change_list = [];
            angular.forEach(collection_list, function(item){
                item.parent = '';
                collection.change_list.push(item);
                if(angular.isDefined(item.nodes)){
                    angular.forEach(item.nodes, function(sub_item){
                        sub_item.parent = item.id;
                        collection.change_list.push(sub_item);
                    });
                }
            });
        };

        collection.change_order = function(){
            var promise = [];
            collection.create_new_list(collection.list);
            angular.forEach(collection.change_list, function(item, index){
                item.ordering = collection.change_list.length - index;
                promise.push(ProductService.update_product_collection(item));
                console.log('update loop');
            });

            $q.all(promise).then(function success(){
                collection.ordering_editing = false;
            });
        };

        $scope.$watch('collection.list', function(newValue, oldValue){
            if(newValue != oldValue && newValue && oldValue){
                collection.ordering_editing = true;
            }
            console.log('watch');
            console.log(collection.ordering_editing);
        }, true);

        collection.set_preview_image = function (element) {
            var reader = new FileReader();
            reader.onload = function (event) {
                collection.detail.image = event.target.result;
                $scope.$apply()
            };
            reader.readAsDataURL(element.files[0]);
        };

        collection.save = function () {
            if (collection.detail.id) {

                // update collection
                ProductService.update_product_collection(collection.detail).then(function success(response){
                    collection.get_collection_list();
                    collection.update_completed = true;
                    collection.init_value();

                    $timeout(function(){
                        collection.update_completed = false;
                    }, 3000);
                });


            } else {
                collection.get_collection_list();

                // add new collection
                ProductService.add_product_collection(collection.detail).then(function success(response){
                    collection.get_collection_list();
                    collection.update_completed = true;
                    collection.init_value();

                    $timeout(function(){
                        collection.update_completed = false;
                    }, 3000);
                }, function error(response){
                    notify({
                        message: 'กรุณาตรวจสอบข้อมูล',
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                });

            }

        };
        
        
        collection.change_slug = function (word) {
            console.log(word);
            collection.detail.slug = collection.slugify(word);
        };
        
        collection.slugify = function (word) {
            return word.toString().toLowerCase().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-').replace(/^-+/, '').replace(/-+$/, '');
        };

    });
