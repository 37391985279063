'use strict';

angular.module('app')
    .factory('ShopInfoService', function ($http, APIURL, RefreshToken, $filter) {

        var shop_info = this;
        var limit = 10;
        var params = {};

        shop_info.get_shop_info = function (filter) {
            return $http({
                url: APIURL + '/shopinfo/',
                method: 'GET'
            });
        };

        shop_info.get_google_token = function (filter) {
            return $http({
                url: APIURL + '/shopinfo/get_google_token/',
                method: 'GET'
            });
        };

        shop_info.update_shop_info = function(data){
            var fd = new FormData();
            angular.forEach(data, function(value, key){
                fd.append(key, value);
            });

            return $http.put(APIURL + '/shopinfo/' + data.id + '/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };


        return shop_info;
    });
