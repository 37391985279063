'use strict';

angular.module('app')
    .controller('OrderAddController', function() {
        var order = this;

        order.init = function() {
	        
        };

        order.init();
        
    });
