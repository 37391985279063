function stateConfig($stateProvider, $urlRouterProvider, $ocLazyLoadProvider, IdleProvider, $httpProvider, KeepaliveProvider) {

    // Configure Idle settings
    IdleProvider.idle(5); // in seconds
    IdleProvider.timeout(120); // in seconds

    $urlRouterProvider.otherwise("/order/list");

    $ocLazyLoadProvider.config({
        // Set to true if you want to see what and when is dynamically loaded
        debug: false
    });

    $httpProvider.interceptors.push('JWTInterceptorProvider');

    $stateProvider

        .state('login', {
            url: "/login",
            templateUrl: "app/login/login.html",
            data: {pageTitle: 'Login', specialClass: 'gray-bg'},
            controller: 'LoginController as login'
        })

        .state('dashboards', {
            abstract: true,
            url: "/dashboards",
            templateUrl: "app/common/content.html",
            data: {pageTitle: 'Dashboard'},
            controller: 'WebAnalyticsController as analytics',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        files: ['assets/js/plugins/footable/footable.all.min.js', 'assets/css/plugins/footable/footable.core.css']
                    }]);
                }
            }
        })

        .state('dashboards.main', {
            url: "/main",
            templateUrl: "app/dashboard/web_analytics.html"
        })

        .state('product', {
            abstract: true,
            url: "/product",
            templateUrl: "app/common/content.html",
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        files: ['assets/js/plugins/footable/footable.all.min.js', 'assets/css/plugins/footable/footable.core.css']
                    }, {
                        name: 'ui.footable',
                        files: ['assets/js/plugins/footable/angular-footable.js']
                    }]);
                }
            }
        })

        .state('product.list', {
            url: "/list",
            templateUrl: "app/products/list.html",
            data: {pageTitle: 'Add New Product'},
            controller: 'ProductListController as product',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        files: ['assets/js/plugins/moment/moment.min.js']
                    }, {
                        name: 'ui.switchery',
                        files: ['assets/css/plugins/switchery/switchery.css', 'assets/js/plugins/switchery/switchery.js', 'assets/js/plugins/switchery/ng-switchery.js']
                    }]);
                }
            }
        })

        .state('product.sort', {
            url: "/order",
            templateUrl: "app/products/product_sort.html",
            data: {pageTitle: 'Product sort'},
            controller: 'ProductSortController as product',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ui.tree',
                            files: ['assets/css/plugins/uiTree/angular-ui-tree.min.css', 'assets/js/plugins/uiTree/angular-ui-tree.min.js']
                        }, {
                            name: 'uiSwitch',
                            files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                        }, {
                            name: 'datePicker',
                            files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                        }, {
                            name: 'cgNotify',
                            files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                        }
                    ]);
                }
            }
        })

        .state('product.detail', {
            url: "/detail/:id",
            templateUrl: "app/products/detail.html",
            data: {pageTitle: 'Product Detail'},
            controller: 'ProductDetailController as product',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        insertBefore: '#loadBefore',
                        name: 'localytics.directives',
                        files: ['assets/css/plugins/chosen/bootstrap-chosen.css', 'assets/js/plugins/chosen/chosen.jquery.js', 'assets/js/plugins/chosen/chosen.js']
                    }, {
                        name: 'cgNotify',
                        files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                    }, {
                        files: ['assets/js/plugins/tinymce/tinymce.min.js']
                    }, {
                        name: 'datePicker',
                        files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                    },  {
                        name: 'uiSwitch',
                        files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                    }, {
                        name: 'ngTagsInput',
                        files: ['assets/js/plugins/ngTags/ng-tags-input.min.js', 'assets/css/plugins/ngTags/ng-tags-input-custom.min.css']
                    }, {
                        files: ['assets/css/plugins/text-spinners/spinners.css']
                    }]);
                }
            }

        })

        .state('product.collection_list', {
            url: "/collection_list",
            templateUrl: "app/products/collection_list.html",
            data: {pageTitle: 'Product Collection'},
            controller: 'ProductCollectionListController as collection',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ui.tree',
                            files: ['assets/css/plugins/uiTree/angular-ui-tree.min.css', 'assets/js/plugins/uiTree/angular-ui-tree.min.js']
                        }, {
                            name: 'uiSwitch',
                            files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                        }, {
                            name: 'datePicker',
                            files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                        }, {
                            name: 'cgNotify',
                            files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                        }
                    ]);
                }
            }
        })

        .state('product.color_list', {
            url: "/color_list",
            templateUrl: "app/products/product_color.html",
            data: {pageTitle: 'Product Color'},
            controller: 'ProductColorListController as product',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ui.tree',
                            files: ['assets/css/plugins/uiTree/angular-ui-tree.min.css', 'assets/js/plugins/uiTree/angular-ui-tree.min.js']
                        }, {
                            name: 'uiSwitch',
                            files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                        }, {
                            name: 'datePicker',
                            files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                        }, {
                            name: 'cgNotify',
                            files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                        },
                        {
                            name: 'colorpicker.module',
                            files: ['assets/css/plugins/colorpicker/colorpicker.css','assets/js/plugins/colorpicker/bootstrap-colorpicker-module.js']
                        }
                    ]);
                }
            }
        })

        .state('product.tag_list', {
            url: "/tag_list",
            templateUrl: "app/products/tag_list.html",
            data: {pageTitle: 'Product Tag'},
            controller: 'ProductTagListController as tag',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ui.sortable',
                            files: ['assets/js/plugins/ui-sortable/sortable.js']
                        }, {
                            files: ['assets/css/plugins/text-spinners/spinners.css']
                        }, {
                            name: 'cgNotify',
                            files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                        }
                    ]);
                }
            }
        })

        .state('order', {
            abstract: true,
            url: "/order",
            templateUrl: "app/common/content.html",
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['assets/js/plugins/footable/footable.all.min.js', 'assets/css/plugins/footable/footable.core.css']
                        },
                        {
                            name: 'ui.footable',
                            files: ['assets/js/plugins/footable/angular-footable.js']
                        }
                    ]);
                }
            }
        })

        .state('order.list', {
            url: "/list",
            templateUrl: "app/order/list.html",
            data: {pageTitle: 'Order'},
            controller: 'OrderListController as order',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        files: ['assets/js/plugins/footable/footable.all.min.js', 'assets/css/plugins/footable/footable.core.css']
                    }, {
                        name: 'uiSwitch',
                        files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                    }, {
                        name: 'datePicker',
                        files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                    }, {
                        files: ['assets/css/plugins/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.css']
                    }, {
                        files: ['assets/js/plugins/filesaver/filesaver.js']
                    }, {
                        name: 'ui.footable',
                        files: ['assets/js/plugins/footable/angular-footable.js']
                    }, {
                        files: ['assets/css/plugins/text-spinners/spinners.css']
                    }]);
                }
            }
        })

        .state('order.add', {
            url: "/add",
            templateUrl: "app/order/add.html",
            data: {pageTitle: 'Order'},
            controller: 'OrderAddController as order',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        files: ['assets/js/plugins/footable/footable.all.min.js', 'assets/css/plugins/footable/footable.core.css']
                    }, {
                        name: 'ui.switchery',
                        files: ['assets/css/plugins/switchery/switchery.css', 'assets/js/plugins/switchery/switchery.js', 'assets/js/plugins/switchery/ng-switchery.js']
                    }, {
                        name: 'ui.footable',
                        files: ['assets/js/plugins/footable/angular-footable.js']
                    }]);
                }
            }
        })

        .state('order.detail', {
            url: "/detail/:id",
            templateUrl: "app/order/detail.html",
            data: {pageTitle: 'Order Detail'},
            controller: 'OrderDetailController as order'
        })

        .state('member', {
            abstract: true,
            url: "/member",
            templateUrl: "app/common/content.html",
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['assets/js/plugins/footable/footable.all.min.js', 'assets/css/plugins/footable/footable.core.css']
                        },
                        {
                            name: 'ui.footable',
                            files: ['assets/js/plugins/footable/angular-footable.js']
                        }
                    ]);
                }
            }
        })

        .state('member.list', {
            url: "/list",
            templateUrl: "app/member/list.html",
            data: {pageTitle: 'Member'},
            controller: 'MemberListController as member'
        })

        .state('content', {
            abstract: true,
            url: "/content",
            templateUrl: "app/common/content.html",
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        files: ['assets/js/plugins/footable/footable.all.min.js', 'assets/css/plugins/footable/footable.core.css']
                    }, {
                        name: 'ui.footable',
                        files: ['assets/js/plugins/footable/angular-footable.js']
                    }]);
                }
            }
        })

        .state('content.blog_list', {
            url: "/content/blog/list",
            templateUrl: "app/blog/list.html",
            data: {pageTitle: 'Add New Blog'},
            controller: 'BlogListController as blog',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            insertBefore: '#loadBefore',
                            name: 'localytics.directives',
                            files: ['assets/css/plugins/chosen/bootstrap-chosen.css', 'assets/js/plugins/chosen/chosen.jquery.js', 'assets/js/plugins/chosen/chosen.js']
                        },
                        {
                            name: 'uiSwitch',
                            files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                        }, {
                            name: 'cgNotify',
                            files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                        },{
                            name: 'ngTagsInput',
                            files: ['assets/js/plugins/ngTags/ng-tags-input.min.js', 'assets/css/plugins/ngTags/ng-tags-input-custom.min.css']
                        }
                    ]);
                }
            }
        })

        .state('content.blog_collection_list', {
            url: "/content/blog/collection_list",
            templateUrl: "app/blog/collection_list.html",
            data: {pageTitle: 'Blog Collection'},
            controller: 'BlogCollectionListController as blog',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ui.tree',
                            files: ['assets/css/plugins/uiTree/angular-ui-tree.min.css', 'assets/js/plugins/uiTree/angular-ui-tree.min.js']
                        }, {
                            name: 'uiSwitch',
                            files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                        }, {
                            name: 'datePicker',
                            files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                        }, {
                            name: 'cgNotify',
                            files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                        }
                    ]);
                }
            }
        })

        .state('content.blog_detail', {
            url: "/content/blog/detail/:id",
            templateUrl: "app/blog/blog_detail.html",
            data: {pageTitle: 'blog Detail'},
            controller: 'BlogDetailController as blog',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        insertBefore: '#loadBefore',
                        name: 'localytics.directives',
                        files: ['assets/css/plugins/chosen/bootstrap-chosen.css', 'assets/js/plugins/chosen/chosen.jquery.js', 'assets/js/plugins/chosen/chosen.js']
                    }, {
                        name: 'cgNotify',
                        files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                    }, {
                        files: ['assets/js/plugins/tinymce/tinymce.min.js']
                    }, {
                        name: 'datePicker',
                        files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                    }, {
                        name: 'uiSwitch',
                        files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                    }, {
                        name: 'ngTagsInput',
                        files: ['assets/js/plugins/ngTags/ng-tags-input.min.js', 'assets/css/plugins/ngTags/ng-tags-input-custom.min.css']
                    }, {
                        files: ['assets/css/plugins/text-spinners/spinners.css']
                    }]);
                }
            }

        })

        .state('content.block_list', {
            url: "content/block/list",
            templateUrl: "app/block/list.html",
            data: {pageTitle: 'Block'},
            controller: 'BlockListController as block',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ui.sortable',
                            files: ['assets/js/plugins/ui-sortable/sortable.js']
                        }, {
                            files: ['assets/css/plugins/text-spinners/spinners.css']
                        }, {
                            name: 'uiSwitch',
                            files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                        }, {
                            name: 'datePicker',
                            files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                        }, {
                            name: 'cgNotify',
                            files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                        }
                    ]);
                }
            }
        })

        .state('content.page_list', {
            url: "/content/page/list",
            templateUrl: "app/page/list.html",
            data: {pageTitle: 'Page List'},
            controller: 'PageListController as page',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'uiSwitch',
                            files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                        }, {
                            name: 'cgNotify',
                            files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                        }
                    ]);
                }
            }
        })

        .state('content.page_detail', {
            url: "content/page/detail/:id",
            templateUrl: "app/page/detail.html",
            data: {pageTitle: 'Page Detail'},
            controller: 'PageDetailController as page',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        insertBefore: '#loadBefore',
                        name: 'localytics.directives',
                        files: ['assets/css/plugins/chosen/bootstrap-chosen.css', 'assets/js/plugins/chosen/chosen.jquery.js', 'assets/js/plugins/chosen/chosen.js']
                    }, {
                        name: 'cgNotify',
                        files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                    }, {
                        files: ['assets/js/plugins/tinymce/tinymce.min.js']
                    }, {
                        name: 'uiSwitch',
                        files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                    }, {
                        name: 'ngTagsInput',
                        files: ['assets/js/plugins/ngTags/ng-tags-input.min.js', 'assets/css/plugins/ngTags/ng-tags-input-custom.min.css']
                    }, {
                        files: ['assets/css/plugins/text-spinners/spinners.css']
                    }]);
                }
            }

        })

        .state('shop_settings', {
            abstract: true,
            url: "/shop_settings",
            templateUrl: "app/common/content.html",
            data: {pageTitle: 'Shop Info'}
        })

        .state('shop_settings.info', {
            url: "/shop_settings/info",
            templateUrl: "app/shop_info/shop_info.html",
            data: {pageTitle: 'Shop Info'},
            controller: 'ShopInfoController as shop_info',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        insertBefore: '#loadBefore',
                        name: 'localytics.directives',
                        files: ['assets/css/plugins/chosen/bootstrap-chosen.css', 'assets/js/plugins/chosen/chosen.jquery.js', 'assets/js/plugins/chosen/chosen.js']
                    }, {
                        name: 'cgNotify',
                        files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                    }, {
                        name: 'summernote',
                        files: ['assets/css/plugins/summernote/summernote.css', 'assets/js/plugins/summernote/summernote.min.js', 'assets/js/plugins/summernote/angular-summernote.min.js']
                    }, {
                        name: 'datePicker',
                        files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                    }, {
                        name: 'ngTagsInput',
                        files: ['assets/js/plugins/ngTags/ng-tags-input.min.js', 'assets/css/plugins/ngTags/ng-tags-input-custom.min.css']
                    }, {
                        files: ['assets/css/plugins/text-spinners/spinners.css']
                    }, {
                        name: 'uiSwitch',
                        files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                    }]);
                }
            }
        })

        .state('shop_settings.editor', {
            url: "/shop_settings/editor",
            templateUrl: "app/template/template_edit.html",
            data: {pageTitle: 'Template Editor'},
            controller: 'TemplateEditController as template',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['assets/css/plugins/jsTree/style.min.css','assets/js/plugins/jsTree/jstree.min.js']
                        },
                        {
                            name: 'ngJsTree',
                            files: ['assets/js/plugins/jsTree/ngJsTree.min.js']
                        },
                        {
                            name: 'AxelSoft',
                            files: ['assets/js/plugins/angularjs-tree-view/style.css',
                                'assets/js/plugins/angularjs-tree-view/script.js']
                        },
                        {
                            serie: true,
                            files: ['assets/css/plugins/codemirror/codemirror.css','assets/css/plugins/codemirror/monokai.css',
                                'assets/js/plugins/codemirror/codemirror.js','assets/js/plugins/codemirror/mode/javascript/javascript.js']
                        },
                        {
                            name: 'ui.codemirror',
                            files: ['assets/js/plugins/ui-codemirror/ui-codemirror.min.js']
                        }
                    ]);
                }
            }
        })

        .state('shipping', {
            abstract: true,
            url: "/shipping",
            templateUrl: "app/common/content.html",
            data: {pageTitle: 'Shipping'}
        })

        .state('shipping.list', {
            url: "/list",
            templateUrl: "app/shipping/list.html",
            data: {pageTitle: 'Shipping List'},
            controller: 'ShippingListController as shipping',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'uiSwitch',
                        files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                    }, {
                        name: 'cgNotify',
                        files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                    }]);
                }
            }
        })

        .state('promotion', {
            abstract: true,
            url: "/promotion",
            templateUrl: "app/common/content.html",
            data: {pageTitle: 'Promotion'}
        })

        .state('promotion.list', {
            url: "/list",
            templateUrl: "app/promotion/list.html",
            data: {pageTitle: 'Promotion List'},
            controller: 'PromotionListController as promotion',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'uiSwitch',
                        files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                    }, {
                        files: ['assets/css/plugins/iCheck/custom.css', 'assets/js/plugins/iCheck/icheck.min.js']
                    }, {
                        name: 'datePicker',
                        files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                    }, {
                        name: 'cgNotify',
                        files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                    }, {
                        files: ['assets/js/plugins/dualListbox/jquery.bootstrap-duallistbox.js','assets/css/plugins/dualListbox/bootstrap-duallistbox.min.css']
                    }, {
                        name: 'frapontillo.bootstrap-duallistbox',
                        files: ['assets/js/plugins/dualListbox/angular-bootstrap-duallistbox.js']
                    }, {
                        insertBefore: '#loadBefore',
                        name: 'localytics.directives',
                        files: ['assets/css/plugins/chosen/bootstrap-chosen.css', 'assets/js/plugins/chosen/chosen.jquery.js', 'assets/js/plugins/chosen/chosen.js']
                    }]);
                }
            }
        })

        .state('payment', {
            abstract: true,
            url: "/payment",
            templateUrl: "app/common/content.html",
            data: {pageTitle: 'Payment'}
        })

        .state('payment.direct', {
            url: "/direct",
            templateUrl: "app/payment/bank_list.html",
            data: {pageTitle: 'Bank List'},
            controller: 'BankListController as bank',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'uiSwitch',
                        files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                    }, {
                        name: 'cgNotify',
                        files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                    }]);
                }
            }
        })

        .state('payment.paypal', {
            url: "/paypal",
            templateUrl: "app/payment/paypal_config.html",
            data: {pageTitle: 'Paypal'},
            controller: 'PaypalConfigController as paypal',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        insertBefore: '#loadBefore',
                        name: 'localytics.directives',
                        files: ['assets/css/plugins/chosen/bootstrap-chosen.css', 'assets/js/plugins/chosen/chosen.jquery.js', 'assets/js/plugins/chosen/chosen.js']
                    }, {
                        name: 'cgNotify',
                        files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                    }, {
                        name: 'summernote',
                        files: ['assets/css/plugins/summernote/summernote.css', 'assets/js/plugins/summernote/summernote.min.js', 'assets/js/plugins/summernote/angular-summernote.min.js']
                    }, {
                        name: 'datePicker',
                        files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                    }, {
                        name: 'uiSwitch',
                        files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                    }, {
                        name: 'ngTagsInput',
                        files: ['assets/js/plugins/ngTags/ng-tags-input.min.js', 'assets/css/plugins/ngTags/ng-tags-input-custom.min.css']
                    }, {
                        files: ['assets/css/plugins/text-spinners/spinners.css']
                    }]);
                }
            }
        })

        .state('branch', {
            abstract: true,
            url: "/branch",
            templateUrl: "app/common/content.html",
            data: {pageTitle: 'Branch'}
        })

        .state('branch.list', {
            url: "/list",
            templateUrl: "app/branch/list.html",
            data: {pageTitle: 'Branch List'},
            controller: 'BranchListController as branch',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ui.tree',
                            files: ['assets/css/plugins/uiTree/angular-ui-tree.min.css', 'assets/js/plugins/uiTree/angular-ui-tree.min.js']
                        }, {
                            name: 'uiSwitch',
                            files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                        }, {
                            name: 'datePicker',
                            files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                        }, {
                            name: 'cgNotify',
                            files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                        }
                    ]);
                }
            }
        })

        .state('branch.stock', {
            url: "/stock",
            templateUrl: "app/branch/stock.html",
            data: {pageTitle: 'Branch Stock'},
            controller: 'BranchStockController as branch',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ui.tree',
                            files: ['assets/css/plugins/uiTree/angular-ui-tree.min.css', 'assets/js/plugins/uiTree/angular-ui-tree.min.js']
                        }, {
                            name: 'uiSwitch',
                            files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                        }, {
                            name: 'datePicker',
                            files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                        }, {
                            name: 'cgNotify',
                            files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                        }, {
                            files: ['assets/js/plugins/filesaver/filesaver.js']
                        }
                    ]);
                }
            }
        })

        .state('branch.transit', {
            url: "/transit",
            templateUrl: "app/branch/branch_transit.html",
            data: {pageTitle: 'Branch transit'},
            controller: 'BranchTransitController as branch',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ui.tree',
                            files: ['assets/css/plugins/uiTree/angular-ui-tree.min.css', 'assets/js/plugins/uiTree/angular-ui-tree.min.js']
                        }, {
                            name: 'uiSwitch',
                            files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                        }, {
                            name: 'datePicker',
                            files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                        }, {
                            name: 'cgNotify',
                            files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                        }, {
                            name: 'datePicker',
                            files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                        }
                    ]);
                }
            }
        })

        .state('branch.pc', {
            url: "/pc",
            templateUrl: "app/branch/pc.html",
            data: {pageTitle: 'Product Consultant'},
            controller: 'PCController as pc',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ui.tree',
                            files: ['assets/css/plugins/uiTree/angular-ui-tree.min.css', 'assets/js/plugins/uiTree/angular-ui-tree.min.js']
                        }, {
                            name: 'uiSwitch',
                            files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                        }, {
                            name: 'datePicker',
                            files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                        }, {
                            name: 'cgNotify',
                            files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                        }
                    ]);
                }
            }
        })

        .state('branch.code', {
            url: "/code",
            templateUrl: "app/branch/branch_code.html",
            data: {pageTitle: 'Branch Code'},
            controller: 'BranchCodeController as code',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ui.tree',
                            files: ['assets/css/plugins/uiTree/angular-ui-tree.min.css', 'assets/js/plugins/uiTree/angular-ui-tree.min.js']
                        }, {
                            name: 'uiSwitch',
                            files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                        }, {
                            name: 'datePicker',
                            files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                        }, {
                            name: 'cgNotify',
                            files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                        }
                    ]);
                }
            }
        })

        .state('branch.daily_order', {
            url: "/daily_order",
            templateUrl: "app/branch/branch_daily_order.html",
            data: {pageTitle: 'Branch Order'},
            controller: 'BranchDailyOrderController as order_c',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ui.tree',
                            files: ['assets/css/plugins/uiTree/angular-ui-tree.min.css', 'assets/js/plugins/uiTree/angular-ui-tree.min.js']
                        }, {
                            name: 'uiSwitch',
                            files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                        }, {
                            name: 'datePicker',
                            files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                        }, {
                            name: 'cgNotify',
                            files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                        }, {
                            name: 'datePicker',
                            files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                        }, {
                            files: ['assets/js/plugins/filesaver/filesaver.js']
                        }
                    ]);
                }
            }
        })

        .state('branch.daily_order_add', {
            url: "/daily_order_add",
            templateUrl: "app/branch/branch_daily_order_add.html",
            data: {pageTitle: 'Branch Order ADD'},
            controller: 'BranchDailyOrderAddController as order',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            insertBefore: '#loadBefore',
                            name: 'localytics.directives',
                            files: ['assets/css/plugins/chosen/bootstrap-chosen.css','assets/js/plugins/chosen/chosen.jquery.js','assets/js/plugins/chosen/chosen.js']
                        }, {
                            name: 'ui.tree',
                            files: ['assets/css/plugins/uiTree/angular-ui-tree.min.css', 'assets/js/plugins/uiTree/angular-ui-tree.min.js']
                        }, {
                            name: 'uiSwitch',
                            files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                        }, {
                            name: 'datePicker',
                            files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                        }, {
                            name: 'cgNotify',
                            files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                        }, {
                            files: ['assets/css/plugins/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.css']
                        }, {
                            name: 'ui.select',
                            files: ['assets/js/plugins/ui-select/select.min.js', 'assets/css/plugins/ui-select/select.min.css']
                        }
                    ]);
                }
            }
        })

        .state('branch.income', {
            url: "/income",
            templateUrl: "app/branch/branch_income.html",
            data: {pageTitle: 'Branch Income'},
            controller: 'BranchIncomeController as order_c',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ui.tree',
                            files: ['assets/css/plugins/uiTree/angular-ui-tree.min.css', 'assets/js/plugins/uiTree/angular-ui-tree.min.js']
                        }, {
                            name: 'uiSwitch',
                            files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                        }, {
                            name: 'datePicker',
                            files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                        }, {
                            name: 'cgNotify',
                            files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                        }, {
                            name: 'datePicker',
                            files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                        }, {
                            files: ['assets/js/plugins/filesaver/filesaver.js']
                        }
                    ]);
                }
            }
        })

        .state('branch.commission', {
            url: "/commission",
            templateUrl: "app/branch/branch_commission.html",
            data: {pageTitle: 'Branch commission'},
            controller: 'BranchCommissionController as order_c',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ui.tree',
                            files: ['assets/css/plugins/uiTree/angular-ui-tree.min.css', 'assets/js/plugins/uiTree/angular-ui-tree.min.js']
                        }, {
                            name: 'uiSwitch',
                            files: ['assets/css/plugins/angular-ui-switch/angular-ui-switch.min.css', 'assets/js/plugins/angular-ui-switch/angular-ui-switch.min.js']
                        }, {
                            name: 'datePicker',
                            files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                        }, {
                            name: 'cgNotify',
                            files: ['assets/css/plugins/angular-notify/angular-notify.min.css', 'assets/js/plugins/angular-notify/angular-notify.min.js']
                        }, {
                            name: 'datePicker',
                            files: ['assets/css/plugins/datapicker/angular-datapicker.css', 'assets/js/plugins/datapicker/angular-datepicker.js']
                        }, {
                            files: ['assets/js/plugins/filesaver/filesaver.js']
                        }
                    ]);
                }
            }
        })

}

angular.module('app').config(stateConfig)
    .run(function ($rootScope, $state) {
        $rootScope.$state = $state;
    });
