'use strict';

angular.module('app')
    .factory('BlogService', function ($http, APIURL, RefreshToken, $filter) {

        var blog = this;
        var limit = 10;
        var params = {};

        blog.get_blog_list = function (filter) {
            var page = filter.page || 1;
            params.limit = limit;
            params.offset = limit*(page-1);
            params.title = filter.query;

            return $http({
                url: APIURL + '/blog/list/',
                method: 'GET',
                params: params
            });
        };

        blog.get_blog_detail = function (id) {
            return $http({
                url: APIURL + '/blog/list/' + id + '/',
                method: 'GET'
            });
        };

        blog.get_blog_category = function () {
            return $http({
                url: APIURL + '/blog/category/',
                method: 'GET'
            });
        };

        blog.get_blog_category_detail = function (id) {
            return $http({
                url: APIURL + '/blog/category/' + id + '/',
                method: 'GET'
            });
        };

        blog.count_blog_in_category = function(category_id){
            return $http({
                url: APIURL + '/blog/category/count_blog_in_category/?id=' + category_id,
                method: 'GET'
            });
        };

        blog.delete_category = function(id){
            return $http({
                url: APIURL + '/blog/category/' + id + '/',
                method: 'DELETE'
            });
        };

        blog.category_form_prepare = function (edit_category) {
            var fd = new FormData();
            fd.append('title', edit_category.title);
            fd.append('slug', edit_category.slug);
            fd.append('description', edit_category.description);
            fd.append('active', edit_category.active);
            fd.append('list', edit_category.list);
            fd.append('note', edit_category.note);
            fd.append('display_per_page', edit_category.display_per_page || 20);

            if(edit_category.parent == '' || edit_category.parent){
                fd.append('parent', edit_category.parent);
            }

            if(edit_category.ordering){
                fd.append('ordering', edit_category.ordering);
            }

            if(edit_category.preview_image){
                fd.append('image', edit_category.preview_image);
            }

            if(edit_category.title && edit_category.slug){
                return fd;
            } else {
                return false;
            }
        };

        blog.add_blog_category = function (edit_category) {

            var fd = this.category_form_prepare(edit_category);

            return $http.post(APIURL + '/blog/category/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        blog.update_blog_category = function (edit_category) {

            var fd = this.category_form_prepare(edit_category);

            return $http.put(APIURL + '/blog/category/' + edit_category.id + '/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        blog.blog_form_prepare = function (blog_detail) {
            var fd = new FormData();
            fd.append('title', blog_detail.title);
            fd.append('slug', blog_detail.slug);
            fd.append('short_description', blog_detail.short_description);
            fd.append('content', blog_detail.content);
            fd.append('active', blog_detail.active);

            if(blog_detail.image){
                fd.append('preview_image', blog_detail.image);
            }

            if(blog_detail.category){
                angular.forEach(blog_detail.category, function(category){
                    fd.append('category', category.id);
                });
            }

            if(blog_detail.title && blog_detail.slug){
                return fd;
            } else {
                return false;
            }
        };

        blog.add_blog = function (blog_detail) {
            var fd = this.blog_form_prepare(blog_detail);

            return $http.post(APIURL + '/blog/list/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        blog.update_blog = function (blog_detail) {
            var fd = this.blog_form_prepare(blog_detail);

            return $http.put(APIURL + '/blog/list/' + blog_detail.id + '/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        blog.delete_blog = function (id) {
            return $http({
                url: APIURL + '/blog/list/' + id + '/',
                method: 'DELETE'
            });
        };

        return blog;
    });
