'use strict';

angular.module('app')
    .factory('ProductService', function ($http, APIURL, RefreshToken, $filter) {

        var product = this;

        product.list_all = function () {
            return $http({
                url: APIURL + '/product/detail/only_parent/',
                method: 'GET'
            });
        };

        product.list = function (page) {
            if (!page) {
                page = 1;
            }
            return $http({
                url: APIURL + '/product/detail/?page=' + page,
                method: 'GET'
            });
        };

        product.form_prepare = function (product) {
            var fd = new FormData();
            fd.append('title', product.title);
            fd.append('short_description', product.short_description);
            fd.append('description', product.description);
            fd.append('price', product.price || 0);
            fd.append('full_price', product.full_price || 0);
            fd.append('capital_cost', product.capital_cost || 0);
            fd.append('active', product.active);
            fd.append('note', product.note);

            if(product.option_parent){
                fd.append('option_parent', product.option_parent);
            }

            if(product.preview_image){
                fd.append('preview_images', product.preview_image);
            }

            if(product.shipping_date._d){
                fd.append('shipping_date', $filter('date')(product.shipping_date._d, 'yyyy-MM-dd HH:mm:ss'));
            }

            if(product.collection){
                fd.append('collection', product.collection.id);
            }

            if(product.color){
                fd.append('color', product.color);
            }

            if(product.slug){
                fd.append('slug', product.slug);
            }

            angular.forEach(this._create_tags_list(product.tags), function(tag_id){
                fd.append('tag', tag_id);
            });

            angular.forEach(this._create_product_related_list(product.related), function(product_id){
                fd.append('related_product', product_id);
            });

            return fd;
        };

        product.add_product = function (product) {

            var fd = this.form_prepare(product);

            return $http.post(APIURL + '/product/detail/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        product.update_product = function (product) {

            var fd = this.form_prepare(product);

            return $http.put(APIURL + '/product/detail/' + product.id +'/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        product.upload_feature_image = function (product_detail_id, file) {

            var fd = new FormData();
            fd.append('product', product_detail_id);
            fd.append('source', file);

            return $http.post(APIURL + '/product/image/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });

        };

        product.update_product_sort = function (product_id_list) {

            var fd = new FormData();
            fd.append('product_id_list', product_id_list);

            return $http.post(APIURL + '/product/list/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });

        };

        product._create_tags_list = function(tags_group){
            var tags_list = [];
            angular.forEach(tags_group, function(tags){
                angular.forEach(tags, function(tag){
                    tags_list.push(tag.id);
               });
            });
            return tags_list;
        };

        product._create_product_related_list = function(product_related_object){
            var product_related_list = [];
            angular.forEach(product_related_object, function(product){
                product_related_list.push(product.id);
            });
            return product_related_list;
        };

        product.add_product_items = function (product_detail_id, item) {
            return $http({
                url: APIURL + '/product/item/',
                method: 'POST',
                data: {
                    product: product_detail_id,
                    attribute: item.attribute_id,
                    sku: item.sku,
                    stock: item.stock,
                    price: item.price,
                    number_of_production: item.number_of_production,
                    option_parent: item.option_parent,
                    option_price: item.option_price,
                    capital_cost: item.capital_cost
                }
            });
        };

        product.add_product_attribute = function (title) {
            return $http({
                url: APIURL + '/product/attributes/',
                method: 'POST',
                data: {
                    title: title
                }
            });
        };

        product.update_product_attribute = function (id, title) {
            return $http({
                url: APIURL + '/product/attributes/'+id+'/',
                method: 'PUT',
                data: {
                    title: title
                }
            });
        };

        // product.update_product_items = function (product_detail_id, item) {
        //     return $http({
        //         url: APIURL + '/product/item/'+ item.id +'/',
        //         method: 'PUT',
        //         data: {
        //             id: item.id,
        //             product: product_detail_id,
        //             sku: item.sku,
        //             name: item.name,
        //             stock: item.stock,
        //             price: item.price,
        //             option_parent: item.option_parent,
        //             option_price: item.option_price
        //         }
        //     });
        // };

        product.delete_product_items = function (product_item_id) {
            return $http({
                url: APIURL + '/product/item/'+ product_item_id +'/',
                method: 'DELETE'
            });
        };

        product.get_product_fields_form = function(){
            return $http({
                url: APIURL + '/product/field/form/',
                method: 'GET'
            });
        };

        product.get_product_fields_data = function(product_detail_id){
            return $http({
                url: APIURL + '/product/field/?product_detail=' + product_detail_id,
                method: 'GET'
            });
        };

        product.get_product_fields_post_url = function(product_detail_id){
            return APIURL + '/product/' + product_detail_id + '/field/form/'
        };

        product.add_product_field_data = function(product_detail_id, field_name, field_slug, field_value, field_type){
            return $http({
                url: APIURL + '/product/field/',
                method: 'POST',
                data: {
                    product_detail: product_detail_id,
                    field_name: field_name,
                    field_slug: field_slug,
                    field_value: field_value,
                    field_type: field_type
                }
            });
        };

        product.update_product_field_data = function(product_detail_id, id, field_name, field_slug, field_value, field_type){
            return $http({
                url: APIURL + '/product/field/'+id+'/',
                method: 'PUT',
                data: {
                    product_detail: product_detail_id,
                    field_name: field_name,
                    field_slug: field_slug,
                    field_value: field_value,
                    field_type: field_type
                }
            });
        };

        product.get_product_detail = function(product_detail_id){
            return $http({
                url: APIURL + '/product/detail/' + product_detail_id +'/',
                method: 'GET'
            });
        };

        product.get_product_items = function(product_detail_id){
            return $http({
                url: APIURL + '/product/item/?product=' + product_detail_id,
                method: 'GET'
            });
        };

        product.get_product_feature_images = function(product_detail_id){
            return $http({
                url: APIURL + '/product/image/?product=' + product_detail_id,
                method: 'GET'
            });
        };

        product.delete_product_feature_images = function(feature_image_id){
            return $http({
                url: APIURL + '/product/image/' + feature_image_id + '/',
                method: 'DELETE'
            });
        };

        product.update_product_feature_images_item = function(image){
            image.feature_image_saving = true;
            return $http({
                url: APIURL + '/product/image/'+image.id+'/item_update/',
                method: 'POST',
                data: {
                    item: image.item
                }
            }).then(function(){
                image.feature_image_saving = false;
            });

        };

        product.get_product_attribute_groups = function(){
            return $http({
                url: APIURL + '/product/attribute_groups/',
                method: 'GET'
            })
        };

        product.get_product_attributes = function(){
            return $http({
                url: APIURL + '/product/attributes/',
                method: 'GET'
            })
        };

        product.get_product_color = function(){
            return $http({
                url: APIURL + '/product/color/',
                method: 'GET'
            })
        };

        product.check_slug_exist = function(slug){
            return $http({
                url: APIURL + '/product/detail/check_slug_exist/?slug='+slug,
                method: 'GET'
            })
        };

        product.get_product_list = function(product){
            var limit = 50;

            if(product.filter.collection){
                var params = {
                    keyword: product.filter.title,
                    collection: product.filter.collection.id,
                    active: product.filter.active,
                    tag: product.filter.tag.id
                }
            } else {
                var params = {
                    keyword: product.filter.title,
                    active: product.filter.active,
                    tag: product.filter.tag.id
                }
            }

            params.limit = limit;
            params.offset = limit*(product.filter.page-1);

            return $http({
                url: APIURL + '/product/detail/',
                method: 'GET',
                params: params
            })
        };

        product.get_product_list_all = function(){
            return $http({
                url: APIURL + '/product/list/',
                method: 'GET'
            })
        };

        //not use
        product.update_product_item = function (product) {
            return $http({
                url: APIURL + '/product/item/'+ item.id +'/',
                method: 'PUT',
                data: {
                    id: item.id,
                    product: product_detail_id,
                    sku: item.sku,
                    name: item.name,
                    stock: item.stock,
                    price: item.price,
                    option_parent: item.option_parent,
                    option_price: item.option_price,
                    capital_cost: item.capital_cost
                }
            });
        };

        product.update_product_item_stock = function (item_id, stock_change, price, sku, capital_cost) {
            return $http({
                url: APIURL + '/product/item/'+ item_id +'/stock_update/',
                method: 'POST',
                data: {
                    item_id: item_id,
                    stock_change: stock_change,
                    price: price,
                    sku: sku,
                    capital_cost: capital_cost
                }
            });
        };

        product.get_product_collection = function(){
            return $http({
                url: APIURL + '/product/collections/',
                method: 'GET'
            });
        };

        product.collection_form_prepare = function (edit_collection) {
            var fd = new FormData();
            fd.append('title', edit_collection.title);
            fd.append('slug', edit_collection.slug);
            fd.append('description', edit_collection.description);
            fd.append('active', edit_collection.active);
            fd.append('list', edit_collection.list);
            fd.append('note', edit_collection.note);
            fd.append('display_per_page', edit_collection.display_per_page || 20);

            if(edit_collection.parent == '' || edit_collection.parent){
                fd.append('parent', edit_collection.parent);
            }

            if(edit_collection.ordering){
                fd.append('ordering', edit_collection.ordering);
            }

            if(edit_collection.preview_image){
                fd.append('image', edit_collection.preview_image);
            }

            if(edit_collection.active_date){
                fd.append('active_date', $filter('date')(edit_collection.active_date._d, 'yyyy-MM-dd HH:mm:ss'));
            }

            if(edit_collection.title && edit_collection.slug){
                return fd;
            } else {
                return false;
            }
        };

        product.add_product_collection = function (edit_collection) {

            var fd = this.collection_form_prepare(edit_collection);

            return $http.post(APIURL + '/product/collections/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        product.update_product_collection = function (edit_collection) {

            var fd = this.collection_form_prepare(edit_collection);

            return $http.put(APIURL + '/product/collections/' + edit_collection.id + '/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        product.delete_collection = function(id){
            return $http({
                url: APIURL + '/product/collections/' + id + '/',
                method: 'DELETE'
            });
        };

        product.count_product_in_collection = function(collection_id){
            return $http({
                url: APIURL + '/product/detail/count_product_in_collection/?id=' + collection_id,
                method: 'GET'
            });
        };

        product.tag_form_prepare = function (group, tag, ordering) {
            var fd = new FormData();
            fd.append('title', tag.title);
            fd.append('slug', tag.slug);
            fd.append('description', tag.description);
            fd.append('group', group);
            if(ordering){
                fd.append('ordering', ordering)
            }
            if(tag.title && tag.slug){
                return fd;
            }
        };

        product.add_product_tag = function (group, tag) {

            var fd = this.tag_form_prepare(group, tag);

            return $http.post(APIURL + '/product/tags/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        product.update_product_tag = function (group, tag, ordering) {

            var fd = this.tag_form_prepare(group, tag, ordering);

            return $http.put(APIURL + '/product/tags/' + tag.id + '/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        product.delete_product_tag = function(id){
            return $http({
                url: APIURL + '/product/tags/' + id + '/',
                method: 'DELETE'
            });
        };

        product.get_product_tag_groups = function(){
            return $http({
                url: APIURL + '/product/tag_groups/',
                method: 'GET'
            })
        };

        product.get_product_tags = function(){
            return $http({
                url: APIURL + '/product/tags/',
                method: 'GET'
            })
        };

        product.add_product_tag_group = function (group) {
            var fd = new FormData();
            fd.append('title', group.title);
            fd.append('description', group.description);

            return $http.post(APIURL + '/product/tag_groups/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        product.update_product_tag_group = function (group) {
            var fd = new FormData();
            fd.append('title', group.title);
            fd.append('description', group.description);

            return $http.put(APIURL + '/product/tag_groups/' + group.id + '/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        product.delete_product_tag_group = function(id){
            return $http({
                url: APIURL + '/product/tag_groups/' + id + '/',
                method: 'DELETE'
            });
        };
        
            product.delete_product_detail = function(id){
            return $http({
                url: APIURL + '/product/detail/' + id + '/',
                method: 'DELETE'
            });
        };

        return product;
    });
