'use strict';

angular.module('app')
    .controller('ProductDetailController', function (ProductService, AssetService, ShopInfoService, $scope, $stateParams, Upload, $q, $state, notify, $filter) {
        var product = this;

        product.is_parent = false;
        product.uploading = false;
        product.collection_list = [];
        product.items = [[], []];
        product.feature_images = [];
        product.detail = {
            id: $stateParams.id || false,
            active: true,
            tags: [],
            shipping_date: new Date()
        };

        product.init_product_item_setting = function () {
            product.detail.product_item_price_list = [];

            product.detail.product_item_stock_list = []; //ค่าเดิม
            product.detail.product_item_new_stock_list = []; //ค่าใหม่

            product.detail.product_item_number_production_list = [];
            product.detail.product_item_current_number_production_list = [];

            product.detail.product_item_stock_change = [];
            product.detail.product_item_sold_list = [];
        };

        product.init = function () {

            /* ---- initial process  ---- */
            // 1. get product collection list
            // 2. get product tags list
            // 3. get product attribute list
            // 4. get product color list

            product.init_product_item_setting();

            var init_promise = [];

            init_promise.push(
                ProductService.list_all().then(function success(response) {
                    product.list_all = response.data;
                })
            );

            init_promise.push(
                ShopInfoService.get_shop_info().then(function success(response) {
                    product.shop_info = response.data[0];
                })
            );

            init_promise.push(
                ProductService.get_product_collection().then(function success(response) {
                    product.collection_list = response.data;
                })
            );

            init_promise.push(
                ProductService.get_product_tag_groups().then(function success(response) {
                    product.tag_groups = response.data;
                })
            );

            init_promise.push(
                ProductService.get_product_tags().then(function success(response) {
                    product.tags_all = response.data;
                    var tag_list = [];
                    angular.forEach(response.data, function (tag) {
                        var tag_group_number = tag.group;
                        try {
                            tag_list[tag_group_number].push(tag);
                        }
                        catch (err) {
                            tag_list[tag_group_number] = [];
                            tag_list[tag_group_number].push(tag);
                        }
                    });
                    product.tag_list = tag_list;
                })
            );

            init_promise.push(
                ProductService.get_product_attribute_groups().then(function success(response) {
                    // set attribute group
                    product.attribute_groups = response.data;

                    product.attribute_group = response.data[0] || null;
                })
            );

            init_promise.push(
                ProductService.get_product_attributes().then(function success(response) {
                    var attribute_list = [];

                    angular.forEach(response.data, function (tag) {
                        var attribute_group_number = tag.group;
                        if (attribute_list[attribute_group_number]) {
                            attribute_list[attribute_group_number].push(tag);
                        } else {
                            attribute_list[attribute_group_number] = [];
                            attribute_list[attribute_group_number].push(tag);
                        }
                    });
                    product.attribute_list = attribute_list;
                })
            );

            init_promise.push(
                ProductService.get_product_color().then(function success(response) {
                    product.color_list = response.data;
                })
            );

            $q.all(init_promise).then(function () {

                // in case edit product
                if (product.detail.id) {
                    ProductService.get_product_detail(product.detail.id).then(function (response) {
                        product.detail = response.data;

                        // set preview image
                        product.detail.image_source = product.detail.preview_images;

                        // get collection
                        angular.forEach(product.collection_list, function (collection) {
                            if (collection.id == product.detail.collection) {
                                product.detail.collection.slug = collection.slug;
                                product.detail.collection.id = collection.id;
                            }
                        });

                        // assign tag
                        product.detail.tags = [];
                        angular.forEach(product.tags_all, function (tag_master) {
                            angular.forEach(product.detail.tag, function (tag) {
                                if (tag_master.id == tag) {
                                    try {
                                        product.detail.tags[tag_master.group].push(tag_master);
                                    }
                                    catch (err) {
                                        product.detail.tags[tag_master.group] = [];
                                        product.detail.tags[tag_master.group].push(tag_master);
                                    }
                                }
                            });
                        });

                        // set product stock
                        product.init_product_item_setting();
                        angular.forEach(product.detail.items, function (item) {

                            if(product.attribute_group == null){
                                item.current_number_of_production = item.number_of_production;
                            } else {
                                product.detail.product_item_price_list[item.attribute] = item.price;
                                product.detail.product_item_current_number_production_list[item.attribute] = item.number_of_production;
                                product.detail.product_item_sold_list[item.attribute] = item.number_of_production - item.stock;

                                product.detail.product_item_stock_list[item.attribute] = item.stock;
                                product.detail.product_item_number_production_list[item.attribute] = item.number_of_production;
                            }

                        });

                        //check is parent product
                        if(product.detail.option_parent) {
                            product.is_parent = false;
                            product.option_items = response.data.items;
                            ProductService.get_product_detail(product.detail.option_parent).then(function success(response) {
                                product.parent = response.data;
                            });
                        } else {
                            product.is_parent = true;
                        }

                        // ordering product item when not have group
                        if(product.attribute_group == null) {
                            console.log('ordering');
                            console.log(product.detail.items);
                            product.detail.items = $filter('orderBy')(product.detail.items, 'attribute_title');
                            console.log(product.detail.items);
                        }

                        product.uploading = false;

                    });
                }

                // add product no attribute group set empty item list
                if(product.attribute_group == null && product.detail.id == false){
                    product.detail.items = [[], [], []];
                }

            }); //after completed queue

        };

        product.init();

        product.append_blank_item = function(){
            if(product.attribute_group == null){
                product.detail.items.push([]);
            }
        };

        product.check_slug_exist = function (slug) {
            product.detail.slug = product.slugify(slug);
            ProductService.check_slug_exist(slug).then(function success(result) {
                product.detail.check_slug_exist = result.data;
            });
        };

        product.change_product_number_production_list = function (attribute_id, item) {

            if(product.attribute_group == null){

            } else {
                product.detail.product_item_new_stock_list[attribute_id] = product.detail.product_item_number_production_list[attribute_id];
                product.detail.product_item_stock_change[attribute_id] = product.detail.product_item_number_production_list[attribute_id] - product.detail.product_item_current_number_production_list[attribute_id];
            }

        };

        product.change_product_title = function () {
            // when change product title product url will change
            if (!product.detail.slug) {
                product.detail.slug = product.slugify(product.detail.title);
                product.check_slug_exist(product.detail.slug);
            }
        };

        product.slugify = function (word) {
            return word.toString().toLowerCase().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-').replace(/^-+/, '').replace(/-+$/, '');
        };

        product.change_product_full_price = function () {
            // when change product full price product price will assign
            product.detail.price = product.detail.full_price;
        };

        product.change_product_price = function () {
            // product price will automatic reduce when set more than product full price.
            // if (product.detail.price > product.detail.full_price) {
            //     product.detail.price = product.detail.full_price;
            // }
        };

        product.select_product_color = function (id) {
            // assign color code
            product.detail.color = id;
        };

        product.set_preview_image = function (element) {
            var reader = new FileReader();
            reader.onload = function (event) {
                product.detail.image_source = event.target.result;
                $scope.$apply()
            };
            reader.readAsDataURL(element.files[0]);
        };

        product.set_feature_image = function (files, errFiles) {
            $scope.files = files;
            $scope.errFiles = errFiles;
            angular.forEach(files, function (file) {
                product.feature_images.push(file);
            });
        };

        product.remove_feature_image_from_list = function (index) {
            product.feature_images.splice(index, 1);
        };

        product.remove_feature_image = function (index) {
            var remove_image = product.detail.images.splice(index, 1);
            ProductService.delete_product_feature_images(remove_image[0].id).then(function () {
                notify({
                    message: $filter('translate')('delete_completed'),
                    classes: 'alert-info',
                    templateUrl: 'app/common/notify.html'
                });
            });
        };

        product.editor_image_upload = function (files) {
            angular.forEach(files, function (file) {
                AssetService.add(product.detail.title, file, 'product').then(function (result) {
                    var image_upload_result = result.data;
                    product.editor.summernote('insertImage', image_upload_result.source, image_upload_result.title);
                });
            });
        };

        product.product_option_selected = function () {
            product.detail.option_parent = product.option_parent_object.id;
            ProductService.get_product_detail(product.option_parent_object.id).then(function success(response){
                product.option_items = response.data.items;
            });

        };

        product.editor_option = {
            'min-height': 300,
            toolbar: [
                ['edit', ['undo', 'redo']],
                ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear']],
                ['fontclr', ['color']],
                ['alignment', ['ul', 'paragraph', 'lineheight']],
                ['table', ['table']],
                ['insert', ['link', 'picture', 'video', 'hr']],
                ['view', ['codeview']]
            ]
        };
        
        product.delete = function() {
            product.deleteing = true;
            ProductService.delete_product_detail(product.detail.id).then(function(){
                $state.go('product.list', {}, {reload: true});
            });
        };

        product.update_item_image = function(file) {
            console.log(file);
            ProductService.update_product_feature_images_item(file).then(function(response){
                console.log(response);
            });
        };

        product.add_update_product = function (redirect) {

            /* ----
             1. add product detail and related product
             2. add product item
             3. add product image
             4. add product custom fields
             ---- */

            var promises = [];

            // check status before upload
            if (product.uploading) {
                // don't do anything if product uploading
                notify({
                    message: 'กำลังอัปโหลดข้อมูล',
                    classes: 'alert-info',
                    templateUrl: 'app/common/notify.html'
                });
                return true;
            }

            if (product.detail.check_slug_exist) {
                // don't do anything if product uploading
                notify({
                    message: 'URL ซ้ำไม่กรุณาเปลี่ยนใหม่',
                    classes: 'alert-info',
                    templateUrl: 'app/common/notify.html'
                });
                return true;
            }

            product.uploading = true;

            if (product.detail.id) {

                // update product
                ProductService.update_product(product.detail).then(function success(result) {

                    // upload feature image
                    if (product.feature_images) {
                        angular.forEach(product.feature_images, function (file) {
                            promises.push(
                                ProductService.upload_feature_image(product.detail.id, file)
                            )
                        });
                    }


                    var current_item_list = [];
                    angular.forEach(product.detail.items, function (item) {
                        current_item_list[item.attribute] = item.id;
                    });

                    // add product no attribute group
                    if(product.attribute_group == null){

                        angular.forEach(product.detail.items, function (item) {
                            if(item.id) {
                                // update
                                var stock_increase = item.number_of_production-item.current_number_of_production;
                                ProductService.update_product_attribute(item.attribute, item.attribute_title);
                                ProductService.update_product_item_stock(item.id, stock_increase, item.price, item.sku, item.capital_cost);
                            } else {
                                // add
                                var item_object = {};
                                if (item.price > 0 && item.attribute_title != '') {
                                    // add product attribute
                                    ProductService.add_product_attribute(item.attribute_title).then(function success(response) {
                                        item_object.attribute_id = response.data.id;
                                        item_object.stock = item.number_of_production;
                                        item_object.sku = item.sku;
                                        item_object.price = item.price;
                                        item_object.number_of_production = item.number_of_production;
                                        item_object.capital_cost = item.capital_cost;
                                        promises.push(ProductService.add_product_items(product.detail.id, item_object));
                                    });
                                }
                            }
                        });

                    } else {

                        // add and update product item with attribute group
                        angular.forEach(product.attribute_list[product.attribute_group.id], function (attribute) {

                            var item_price = product.detail.product_item_price_list[attribute.id];
                            if (item_price > 0) {
                                var item_id = current_item_list[attribute.id];

                                if (item_id) {
                                    // update
                                    var stock_increase = product.detail.product_item_stock_change[attribute.id] || 0;
                                    if(stock_increase >= 0){
                                        ProductService.update_product_item_stock(item_id, stock_increase, item_price, 0);
                                    }else {
                                        return alert('ไม่สามารถบันทึกสินค้าที่มีจำนวนคงเหลือติดลบได้');
                                    }

                                } else {
                                    // add
                                    var item = {};
                                    item.attribute_id = attribute.id;
                                    item.stock = product.detail.product_item_number_production_list[attribute.id];
                                    item.price = product.detail.product_item_price_list[attribute.id];
                                    item.number_of_production = product.detail.product_item_number_production_list[attribute.id];
                                    promises.push(ProductService.add_product_items(product.detail.id, item));
                                }
                            }

                        });
                    }

                    $q.all(promises).then(function () {
                        if(redirect){
                            $state.go('product.list', {}, {reload: true});
                        } else {
                            product.init();
                            // product.uploading = false;
                            product.feature_images = [];
                        }
                    });

                }, function error(result) {
                    notify({
                        message: 'ข้อมูลไม่ครบ กรุณาตรวจสอบข้อมูล',
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                    product.uploading = false;
                });

            } else {

                // add product
                ProductService.add_product(product.detail).then(function success(result) {
                    var product_detail_id = result.data.id;


                    if(product.attribute_group == null && !product.option_items){
                        // add product no attribute group
                        angular.forEach(product.detail.items, function (item) {
                            var item_object = {};
                            if (item.price > 0 && item.attribute_title != '') {
                                // add product attribute
                                ProductService.add_product_attribute(item.attribute_title).then(function success(response) {
                                    item_object.attribute_id = response.data.id;
                                    item_object.stock = item.number_of_production;
                                    item_object.sku = item.sku;
                                    item_object.price = item.price;
                                    item_object.number_of_production = item.number_of_production;
                                    item_object.capital_cost = item.capital_cost;
                                    promises.push(ProductService.add_product_items(product_detail_id, item_object));
                                });
                            }
                        });

                    } else if (product.attribute_group && !product.option_items) {
                        // add product item with product group
                        angular.forEach(product.attribute_list[product.attribute_group.id], function (attribute) {
                            var item = {};
                            if (product.detail.product_item_price_list[attribute.id] > 0) {
                                item.attribute_id = attribute.id;
                                item.stock = product.detail.product_item_number_production_list[attribute.id];
                                item.price = product.detail.product_item_price_list[attribute.id];
                                item.number_of_production = product.detail.product_item_number_production_list[attribute.id];
                                promises.push(ProductService.add_product_items(product_detail_id, item));
                            }
                        });
                    } else if (product.option_items) {
                        // add option item
                        angular.forEach(product.option_items, function (option_item) {
                            var item = {};
                            if (option_item.price > 0) {
                                item.attribute_id = option_item.attribute;
                                item.stock = 0;
                                item.price = 0;
                                item.number_of_production = 0;
                                item.option_parent = option_item.id;
                                item.option_price = option_item.option_price;
                                item.sku = option_item.sku;
                                promises.push(ProductService.add_product_items(product_detail_id, item));
                            }
                        });
                    }

                    // upload feature image
                    if (product.feature_images) {
                        angular.forEach(product.feature_images, function (file) {
                            promises.push(
                                ProductService.upload_feature_image(product_detail_id, file)
                            )
                        });
                    }

                    // waiting for finish all process and redirect to product list
                    $q.all(promises).then(function () {
                        if(redirect){
                            $state.go('product.list', {}, {reload: true});
                        } else {
                            product.init();
                            $state.go('product.detail', {id: product_detail_id})
                            // product.uploading = false;
                        }
                    });
                }, function error() {
                    notify({
                        message: 'ข้อมูลไม่ครบ กรุณาตรวจสอบข้อมูล',
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                    product.uploading = false;
                });

            }

        };

        $scope.$watch('product.detail.price', function(newValue, oldValue){
            if(newValue != oldValue && newValue && oldValue){
                angular.forEach(product.detail.product_item_price_list, function(price, index){
                    product.detail.product_item_price_list[index] = product.detail.price;
                });
            }
        }, true);

    });
