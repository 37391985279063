'use strict';

angular.module('app')
    .factory('APIURL', function($location){
        var api_url;
        var admin_sub_domain = $location.host().split('.')[0];
        var parser = document.createElement('a');
        parser.href = $location.host();

        function get_sub_domain() {
            if(admin_sub_domain === 'admin') {
                return false;
            } else {
                return admin_sub_domain.split('-')[1];
            }
        }

        function get_api_domain() {
            var sub_domain = get_sub_domain();
            var host = parser.hostname.slice(admin_sub_domain.length+1);
            if(sub_domain){
                api_url = parser.protocol+'//'+sub_domain+'.'+host+'/api';
            } else {
                api_url = parser.protocol+'//'+host+'/api';
            }

            if(window.location.origin === 'http://shoppo:3000') {
                api_url = 'http://shoppo:8000/api';
            } else if(window.location.origin === 'http://192.168.0.107:3000') {
                api_url = 'http://192.168.0.107:8000/api';
            }
        }

        get_api_domain();

        // var host = $location.host();
        // var protocol = $location.protocol();
        // var site_config = {
        //     'shoppo': 'http://shoppo:8000/api'
        // };

        // if(site_config[host]){
        //     // dev url
        //     api_url = site_config[host];
        // } else if (host.split('.')[0].substring(0,11) == 'admin-') {
        //     // sub domain
        //     var demo_sub_domain = host.split('.')[0].substring(6, host.split('.')[0].length);
        //     api_url = protocol+'://'+demo_sub_domain+'.'+host.split('.')[1]+'/api';
        // }
        // else {
        //     // production default admin is admin.domain.com just use only domain
        //     api_url = protocol+'://'+host+'/api';
        // }

        // console.log(window.location.hostname);
        // console.log(host);
        // console.log(api_url);

        return api_url;

    });
