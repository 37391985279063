'use strict';

angular.module('app')
    .controller('BranchIncomeController', function (BranchService, $scope, OrderService) {
        var order = this;
        order.filter = {page: 1, start_date: '', end_date: '', order__item__product__title: ''};
        order.branch = {list: {}};
        order.is_loading_excel_file = false;

        order.get_income = function() {
            BranchService.income(order.filter).then(function (response){
                order.list = response.data;
                order.updating = false;
            });
            OrderService.get_summary_order_count(order.filter).then(function(response){
                order.order_count = response.data.count;
            });
            OrderService.get_summary_order_item_count(order.filter).then(function(response){
                order.order_item_count = response.data.count;
            });
            OrderService.get_summary_order_income(order.filter).then(function(response){
                order.order_income = response.data.count;
            });
            OrderService.get_summary_order_cost(order.filter).then(function(response){
                order.order_cost = response.data.count;
            })
        };

        order.get_income_summary = function() {
            BranchService.income_summary(order.filter).then(function(response){
                order.summary = response.data;
                order.summary.income = 0;
                order.summary.cost = 0;
                order.summary.gp = 0;
                order.summary.profit = 0;
                angular.forEach(order.summary, function(income){
                    order.summary.income += income.total_income_summary;
                    order.summary.cost += income.cost_of_goods_summary;
                    order.summary.gp += income.total_gp_sum;
                    order.summary.profit += income.total_profit_summary;
                });
            });
        }

        BranchService.get_branch().then(function success(response){
            order.branch.list = response.data;
        });

        $scope.$watchGroup(['order_c.filter.start_date', 'order_c.filter.end_date', 'order_c.filter.page', 'order_c.filter.branch', 'order_c.filter.order__item__product__title'], function () {
            order.updating = true;
            order.get_income();
            order.get_income_summary();
            console.log('watch');
        });

        order.export_excel = function() {
            order.is_loading_excel_file = true;
            BranchService.income_excel(order.filter).then(function success(response){
                var blob = new Blob([response.data], { type: "application/xls" });
                saveAs(blob, 'income.xls');
                order.is_loading_excel_file = false;
            });
        };

    });