'use strict';

angular.module('app')
    .factory('OrderService', function ($http, APIURL, RefreshToken, $filter) {

        var order = this;
        var limit = 50;

        order.get_order_list = function (filter) {

            if(angular.isObject(filter.updated_from)){
                var updated_from = $filter('date')(filter.updated_from['_d'], 'yyyy-MM-dd');
            }
            if(angular.isObject(filter.added_from)){
                var added_from = $filter('date')(filter.added_from['_d'], 'yyyy-MM-dd');
            }

            var page = filter.page;
            if (!page) {
                page = 1;
            }

            var params = {
                id: filter.order_id,
                status: filter.status,
                customer_name: filter.customer_name,
                min_total_price: filter.min_total_price,
                updated_from: updated_from,
                added_from: added_from,
                ordering: filter.ordering,
                limit: limit,
                offset: limit*(page-1),
                payment_type: filter.payment_type
            };
            if(filter.bank) {
                params['payment_info__bank'] = filter.bank;
            }

            return $http({
                url: APIURL + '/checkout/order/?page=' + page,
                method: 'GET',
                params: params
            });
        };

        order.update_order_status = function(order){
            return $http({
                url: APIURL + '/checkout/order/'+ order.id +'/',
                method: 'PUT',
                data: {
                    id: order.id,
                    status: order.status,
                    customer_address: order.customer_address,
                    customer_city: order.customer_city,
                    customer_email: order.customer_email,
                    customer_last_name: order.customer_last_name,
                    customer_name: order.customer_name,
                    customer_phone: order.customer_phone,
                    customer_postcode: order.customer_postcode,
                    customer_province: order.customer_province,
                    order_identity: order.order_identity,
                    payment_type: order.payment_type,
                    shipping_cost: order.shipping_cost,
                    total_price: order.total_price,
                    shipping_code: order.shipping_code
                }
            });
        };

        order.get_excel_file = function(list_of_order){
            return $http({
                url: APIURL + '/checkout/order/export_excel/',
                method: 'GET',
                params: {
                    order_list: list_of_order
                },
                headers : {
                    'Content-Type': "application/xlsx",
                },
                responseType : 'arraybuffer'
            });
        };

        order.get_pdf_file = function(list_of_order){
            return $http({
                url: APIURL + '/checkout/order/export_pdf/',
                method: 'GET',
                params: {
                    order_list: list_of_order
                },
                headers : {
                    'Content-Type': "application/pdf",
                },
                responseType : 'arraybuffer'
            });
        };

        order.upload_excel_file = function(file){
            var fd = new FormData();
            fd.append('file', file);

            return $http.post(APIURL + '/checkout/order/import_excel/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };
        
        order.get_order_status_change_log = function(order_id){
            return $http({
                url: APIURL + '/checkout/status_change_history/?order=' + order_id,
                method: 'GET'
            });
        };
        
        order.get_order_detail = function(order_id){
            return $http({
                url: APIURL + '/checkout/order/' + order_id + '/',
                method: 'GET'
            });
        };

        order.get_bank_list = function(){
            return $http({
                url: APIURL + '/checkout/order/direct_payment_bank/',
                method: 'GET'
            });
        };

        order.update_status_by_order_id = function(order_id, status){
            var fd = new FormData();
            fd.append('order_id', order_id);
            fd.append('status', status);

            return $http.post(APIURL + '/checkout/order/update_status_by_order_id/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        order.get_summary_order_count = function(filter) {
            var params = {};
            angular.forEach(filter, function(value, key){
                if(key === 'start_date' && value) {
                    params[key] = $filter('date')(filter[key]['_d'], 'yyyy-MM-dd');
                } else if(key === 'end_date' && value) {
                    params[key] = $filter('date')(filter[key]['_d'], 'yyyy-MM-dd 23:59');
                } else if(value){
                    params[key] = filter.value;
                }
            });
            return $http({
                url: APIURL + '/checkout/order/count_order/',
                method: 'GET',
                params: params
            });
        };

        order.get_summary_order_item_count = function(filter) {
            var params = {};
            angular.forEach(filter, function(value, key){
                if(key === 'start_date' && value) {
                    params[key] = $filter('date')(filter[key]['_d'], 'yyyy-MM-dd');
                } else if(key === 'end_date' && value) {
                    params[key] = $filter('date')(filter[key]['_d'], 'yyyy-MM-dd 23:59');
                } else if(value){
                    params[key] = filter.value;
                }
            });

            return $http({
                url: APIURL + '/checkout/order/count_item/',
                method: 'GET',
                params: params
            });
        };

        order.get_summary_order_income = function(filter) {
            var params = {};
            angular.forEach(filter, function(value, key){
                if(key === 'start_date' && value) {
                    params[key] = $filter('date')(filter[key]['_d'], 'yyyy-MM-dd');
                } else if(key === 'end_date' && value) {
                    params[key] = $filter('date')(filter[key]['_d'], 'yyyy-MM-dd 23:59');
                } else if(value){
                    params[key] = filter.value;
                }
            });

            return $http({
                url: APIURL + '/checkout/order/summary_income/',
                method: 'GET',
                params: params
            });
        };

        order.get_summary_order_cost = function(filter) {
            var params = {};
            angular.forEach(filter, function(value, key){
                if(key === 'start_date' && value) {
                    params[key] = $filter('date')(filter[key]['_d'], 'yyyy-MM-dd');
                } else if(key === 'end_date' && value) {
                    params[key] = $filter('date')(filter[key]['_d'], 'yyyy-MM-dd 23:59');
                } else if(value){
                    params[key] = filter.value;
                }
            });

            return $http({
                url: APIURL + '/checkout/order/summary_cost/',
                method: 'GET',
                params: params
            });
        };

        return order;
    });
