'use strict';

angular.module('app')
    .directive('tinyMce', function ($parse, AssetService) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var model = attrs.model;

                scope.$watch(model, function (newValue, oldValue) {
                    if (newValue) {
                        tinymce.activeEditor.setContent(newValue);
                    }
                });

                var assign = function (obj, prop, value) {
                    if (typeof prop === "string")
                        prop = prop.split(".");

                    if (prop.length > 1) {
                        var e = prop.shift();
                        assign(obj[e] =
                                Object.prototype.toString.call(obj[e]) === "[object Object]"
                                    ? obj[e]
                                    : {},
                            prop,
                            value);
                    } else
                        obj[prop[0]] = value;
                };

                tinymce.init({
                    selector: '.' + attrs.class,
                    branding: false,
                    min_height: 500,
                    menubar: false,
                    plugins: [
                        'autoresize advlist autolink lists link charmap print preview anchor image',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table contextmenu paste code emoticons colorpicker textcolor'
                    ],
                    toolbar: 'undo redo | styleselect | bold italic removeformat | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | media | forecolor backcolor emoticons | code preview',
                    content_css: [],
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    file_picker_callback: function (cb, value, meta) {
                        var input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');

                        input.onchange = function () {
                            var file = this.files[0];
                            AssetService.add('', file, 'blog').then(function (result) {
                                cb(result.data.source, {title: result.data.title});
                            });
                        };

                        input.click();
                    }, setup: function (editor) {
                        editor.on('blur', function (e) {
                            assign(scope, model, tinymce.activeEditor.getContent());
                        });
                    }, init_instance_callback: function (editor) {
                        editor.on('SetContent', function (e) {
                        });
                    }
                });

                $(window).scroll(function () {
                    if ($('.mce-container-body').length > 1) {
                        var menu_position = $('.mce-container-body').offset().top;
                        var current_position = $('html').scrollTop();
                        var menu = $('.mce-toolbar-grp');
                        var maximum_visible_position = menu_position + $('.mce-container-body').height();
                        if (current_position > menu_position && current_position < maximum_visible_position) {
                            menu.css({
                                'position': 'fixed',
                                top: current_position - $('.wrapper-content').offset().top,
                                width: $('.mce-container-body').width(),
                                'box-shadow': '0 0 4px #333333',
                            });
                        } else if (current_position > maximum_visible_position) {
                            menu.css({'position': 'relative', top: 0, 'box-shadow': 'none'});
                        } else {
                            menu.css({'position': 'relative', top: 0, 'box-shadow': 'none'});
                        }
                    }

                });

            }
        };
    });