'use strict';

angular.module('app')
    .controller('BranchCodeController', function (BranchService, notify, $timeout) {

        var code = this;
        console.log('code');

        code.detail = {};
        code.branch = {list: {}};

        code.init_value = function() {
            code.detail = {};
            code.adding_status = true;
            code.delete_status = false;
            code.editing_status = false;
        };

        code.init = function () {

            code.adding_status = true;
            code.delete_status = false;
            code.editing_status = false;

            BranchService.get_branch().then(function success(response) {
                code.branch.list = response.data;
                console.log(response.data)
            });

            BranchService.get_code().then(function (response){
                code.list = response.data;
                console.log(code.list);
            });
        };

        code.init();

        code.edit = function (edit_code) {
            code.adding_status = false;
            code.editing_status = true;
            angular.copy(edit_code, code.detail);
            console.log(edit_code);
            code.detail.branch = String(code.detail.branch['id']);
        };

        code.cancel = function() {
            code.adding_status = true;
            code.editing_status = false;
            code.detail = {};
        };

        code.save = function () {
            console.log(code.detail);

            if (code.detail.id) {

                // update collection
                BranchService.update_code(code.detail).then(function(){
                    code.update_completed = true;
                    code.init();
                    code.init_value();
                    $timeout(function(){
                        code.update_completed = false;
                    }, 3000);

                }, function(){
                    notify({
                            message: 'ไม่สามารถเพิ่มโค้ดใหม่ได้',
                            classes: 'alert-info',
                            templateUrl: 'app/common/notify.html'
                        });
                    return false;
                });


            } else {

                BranchService.add_code(code.detail).then(function(){
                    code.update_completed = true;
                    code.init();
                    code.init_value();
                    $timeout(function(){
                        code.update_completed = false;
                    }, 3000);

                }, function(){
                    notify({
                            message: 'ไม่สามารถเพิ่มโค้ดใหม่ได้',
                            classes: 'alert-info',
                            templateUrl: 'app/common/notify.html'
                        });
                    return false;
                });

            }

        };


    });
