'use strict';

angular.module('app')
    .controller('ProductTagListController', function (ProductService, AssetService, $scope, notify, $filter, $uibModal, $stateParams, Upload, $q, $state) {

        var tag = this;
        var modalInstance;
        tag.groups = [];
        tag.list = [];
        tag.status_update_tag_ordering = {active: false, updating: false};
        tag.modal = {};

        tag.sortableOptions = {
            connectWith: ".connectList"
        };

        tag.modal.open_group_edit = function (group) {
            tag.edit_group = {};
            if (group) {
                tag.edit_group = {
                    id: group.id,
                    title: group.title,
                    description: group.description,
                    tag: group.tag
                }
            }
            modalInstance = $uibModal.open({
                templateUrl: 'app/products/modal_group_edit.html',
                scope: $scope
            });
        };

        tag.modal.cancel = function () {
            console.log('cancel');
            modalInstance.close();
        };

        tag.modal.save = function () {
            console.log('save');
            if (tag.edit_group.id) {
                // update
                ProductService.update_product_tag_group(tag.edit_group).then(function () {
                    tag.get_tag_group();
                    modalInstance.close();
                });
            } else {
                // add
                ProductService.add_product_tag_group(tag.edit_group).then(function () {
                    tag.get_tag_group();
                    modalInstance.close();
                });
            }
        };

        tag.modal.delete = function () {
            if (tag.edit_group.tag.length == 0) {
                // update
                ProductService.delete_product_tag_group(tag.edit_group.id).then(function () {
                    tag.get_tag_group();
                    modalInstance.close();
                });
            } else {
                notify({
                    message: $filter('translate')("can't delete"),
                    classes: 'alert-info',
                    templateUrl: 'app/common/notify.html'
                });
            }
        };

        tag.get_tag_group = function () {
            ProductService.get_product_tag_groups().then(function (response) {
                tag.groups = response.data;

                tag.status_update_tag_ordering = {active: false, updating: false};

                // update tag ordering
                $scope.$watch('tag.groups', function (newValue, oldValue) {
                    if (newValue != oldValue && newValue && oldValue) {
                        tag.status_update_tag_ordering.active = true;
                    }
                }, true);
            });
        };

        tag.update_tag_ordering = function () {
            tag.status_update_tag_ordering.updating = true;

            angular.forEach(tag.groups, function (group) {
                var counter = group.tag.length;
                angular.forEach(group.tag, function (the_tag) {
                    ProductService.update_product_tag(group.id, the_tag, counter).then(function success() {
                        tag.status_update_tag_ordering = {active: false, updating: false};
                    }, function error(response) {
                        notify({
                            message: $filter('translate')(response.data),
                            classes: 'alert-info',
                            templateUrl: 'app/common/notify.html'
                        });
                    });
                    counter = counter - 1;
                });
            });
        };

        tag.add_tag = function (group) {
            group.data = [];
            group.edit_status = true;
        };

        tag.add_tag_cancel = function (group) {
            group.edit_status = false;
        };

        tag.edit = function (group, edit_tag) {
            group.edit_status = true;
            group.data = edit_tag;
        };

        tag.delete = function (group, edit_tag) {
            ProductService.delete_product_tag(edit_tag.id).then(function success(response) {
                group.edit_status = false;
                tag.get_tag_group();
            });
        };

        tag.save = function (group) {
            if (group.data.id) {
                // update
                ProductService.update_product_tag(group.id, group.data).then(function success(response) {
                    group.edit_status = false;
                    group.data = [];
                    tag.get_tag_group();
                }, function error(response) {
                    alert(response.data.title);
                });
            } else {
                // add
                ProductService.add_product_tag(group.id, group.data).then(function success(response) {
                    group.edit_status = false;
                    group.data = [];
                    tag.get_tag_group();
                }, function error(response) {
                    alert(response.data.title);
                });
            }
        };

        tag.init = function () {
            tag.get_tag_group();
        };

        tag.init();

    });
