'use strict';

angular.module('app')
    .factory('AssetService', function ($http, APIURL) {

        var Asset = this;

        Asset.add = function (title, file, type) {
            var fd = new FormData();
            fd.append('title', title);
            fd.append('type', type);
            fd.append('source', file);

            return $http.post(APIURL + '/asset/image/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        return Asset;

    });
