'use strict';

angular.module('app')
    .controller('ShippingListController', function (ShippingService, $uibModal, $scope, notify, $filter) {

        var shipping = this;
        var modalInstance;
        shipping.modal = {};
        shipping.edit = {};
        shipping.filter = {
            'page': 1,
            'query': ''
        };

        shipping.get_shipping_list = function () {
            ShippingService.get_shipping_list(shipping.filter).then(function success(response){
                shipping.list = response.data;
            });
        };

        shipping.init = function () {
            shipping.get_shipping_list();
        };

        shipping.init();

        shipping.modal.open = function (size, shipping_data) {
            angular.copy(shipping_data, shipping.edit);
            modalInstance = $uibModal.open({
                templateUrl: 'app/shipping/modal_shipping.html',
                scope: $scope,
                size: size,
                backdrop: 'static'
            });
        };

        shipping.modal.cancel = function () {
            shipping.edit = {};
            modalInstance.close();
        };

        shipping.modal.save = function () {
            if(shipping.edit.id){
                //update
                ShippingService.update_shipping(shipping.edit).then(function success(response){
                    shipping.get_shipping_list();
                    modalInstance.close();
                }, function error(response){
                    notify({
                        message: response.data,
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                });
            }else {
                //add
                ShippingService.add_shipping(shipping.edit).then(function success(response){
                    shipping.get_shipping_list();
                    modalInstance.close();
                }, function error(response){
                    notify({
                        message: response.data,
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                });
            }

        };

        shipping.modal.delete = function () {
            ShippingService.can_delete(shipping.edit.id).then(function success(response){
                if(response.data) {
                    ShippingService.delete_shipping(shipping.edit).then(function success(response){
                        shipping.get_shipping_list();
                        modalInstance.close();
                    }, function error(response){
                        notify({
                            message: response.data,
                            classes: 'alert-info',
                            templateUrl: 'app/common/notify.html'
                        });
                    });
                } else {
                    notify({
                        message: $filter('translate')('can not delete'),
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                }
            });

        };

    });
