'use strict';

angular.module('app')
    .factory('JWTInterceptorProvider', function($q, $location, $localStorage, $injector, $rootScope, $window){
        return {
            'request': function (config) {
                config.headers = config.headers || {};
                if ($localStorage.token) {
                    config.headers.Authorization = 'JWT ' + $localStorage.token;
                }
                $rootScope.$emit('requestTrigger');
                return config;
            },
            'responseError': function (response) {
                if (response.status === 401 || response.status === 403) {
                    // Use $injector to call $state and use $state to call transitionTo login page
                	$injector.get('$state').go('login');
                } else if (response.status === 404) {
                    // Server Error
                }
                return $q.reject(response);
            }
        };

    });
