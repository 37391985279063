'use strict';

angular.module('app')
    .factory('BlockService', function ($http, APIURL, RefreshToken, $filter) {

        var block = this;

        /* --------------------------------- */
        /* ----- Block Group Command ------- */
        /* --------------------------------- */

        block.get_groups = function () {
            return $http({
                url: APIURL + '/block/groups/',
                method: 'GET'
            });
        };

        block.group_form_prepare = function (detail) {
            var fd = new FormData();
            fd.append('title', detail.title);
            fd.append('description', detail.description);
            fd.append('slug', detail.slug);
            if(detail.title){
                return fd;
            } else {
                return false;
            }
        };

        block.add_group = function (detail) {
            var fd = this.group_form_prepare(detail);

            return $http.post(APIURL + '/block/groups/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        block.update_block_group = function (detail) {
            var fd = this.group_form_prepare(detail);

            return $http.put(APIURL + '/block/groups/'+detail.id+'/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        block.delete_block_group = function(group_id) {
            return $http({
                url: APIURL + '/block/groups/'+ group_id + '/',
                method: 'DELETE'
            });
        };

        /* --------------------------------- */
        /* -------- Block Command ---------- */
        /* --------------------------------- */
        block.form_prepare = function (detail) {
            var fd = new FormData();
            fd.append('title', detail.title);
            fd.append('text', detail.text);
            fd.append('link', detail.link);
            fd.append('active', detail.active);

            if(angular.isDefined(detail.active_date)){
                if(angular.isDefined(detail.active_date._d)){
                    fd.append('active_date', $filter('date')(detail.active_date._d, 'yyyy-MM-dd 00:00:00'));
                }
            } else {
                // fd.append('active_date', detail.active_date);
            }

            if(!angular.isDefined(detail.active)){
                fd.append('active', false);
            }

            if(detail.image){
                fd.append('preview_image', detail.image);
            }

            if(detail.file_upload){
                fd.append('file', detail.file_upload);
            }

            if(detail.title){
                return fd;
            } else {
                return false;
            }
        };

        block.add = function (group_id, detail) {
            console.log(detail);
            var fd = this.form_prepare(detail);
            fd.append('group', group_id);

            return $http.post(APIURL + '/block/list/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        block.update = function (group_id, detail, counter) {
            console.log(detail);
            var fd = this.form_prepare(detail);
            fd.append('group', group_id);
            if(counter){
                fd.append('ordering', counter);
            }

            return $http.put(APIURL + '/block/list/' + detail.id + '/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        block.delete = function (id) {
            return $http({
                url: APIURL + '/block/list/' + id + '/',
                method: 'DELETE'
            });
        };

        return block;
    });
