'use strict';

angular.module('app')
    .factory('ProductColorService', function ($http, APIURL) {

        var color = this;

        color.get_color_list = function () {
            return $http({
                url: APIURL + '/product/color/',
                method: 'GET'
            });
        };

        color.color_form_prepare = function (edit_collection) {
            var fd = new FormData();
            fd.append('title', edit_collection.title);
            fd.append('color_code', edit_collection.color_code);

            if(edit_collection.preview_image){
                fd.append('color_image_source', edit_collection.preview_image);
            }

            if(edit_collection.title){
                return fd;
            } else {
                return false;
            }
        };

        color.add = function(my_color) {
            var fd = this.color_form_prepare(my_color);

            return $http.post(APIURL + '/product/color/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        color.update = function (my_color) {

            var fd = this.color_form_prepare(my_color);

            return $http.put(APIURL + '/product/color/' + my_color.id + '/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        color.count_product = function(color_id) {
            return $http({
                url: APIURL + '/product/color/count?id='+color_id,
                method: 'GET'
            });
        };

        color.delete = function(color_id) {
            return $http({
                url: APIURL + '/product/color/'+color_id+'/',
                method: 'DELETE'
            });
        };


        return color;
    });
