'use strict';

angular.module('app')
    .controller('ProductListController', function (ProductService, $scope, $stateParams, APIURL, Upload, $q, $state) {

        var product = this;
        product.filter = {title: '', active: '', collection: '', tag: '', page: 1};
        product.item_order_by = 'attribute_ordering';

        product.get_product_list = function () {
            product.loading = true;
            ProductService.get_product_list(product).then(function success(response) {
                product.list = response.data;
                product.need_refresh = false;
                product.loading = false;
            });
        };

        // Check Attribute Group
        ProductService.get_product_attribute_groups().then(function success(response) {
            // set attribute group
            if(response.data.length === 0){
                product.item_order_by = 'attribute_title';
            }
        });

        product.init = function () {
            ProductService.get_product_collection().then(function success(response) {
                product.collection_list = response.data;
            });
            ProductService.get_product_tags().then(function success(response){
                product.tag_list = response.data;
            });
        };

        product.init();

        // action went change product filter
        $scope.$watchGroup(['product.filter.active', 'product.filter.title', 'product.filter.collection', 'product.filter.page', 'product.filter.tag'], function () {
            product.get_product_list();
        });

        product.change_product_status = function(edit_product){
            edit_product.collection.id = edit_product.collection[0];
            ProductService.update_product(edit_product).then(function success(){
                product.need_refresh = true;
            });
        };

        // save button
        product.save_product_stock = function (edit_product) {
            var update_stock_promise = [];
            angular.forEach(edit_product.items, function (item, index) {
                if (item.stock_change != undefined) {
                    console.log(item.stock + (item.stock_change-item.number_of_production));
                    if(item.stock + (item.stock_change-item.number_of_production) >= 0 ) {
                        update_stock_promise.push(ProductService.update_product_item_stock(item.id, item.stock_change-item.number_of_production)
                        .then(function success(response) {
                            item.stock = response.data.stock;
                            item.number_of_production = response.data.number_of_production;
                            item.stock_change=undefined;
                        }));
                    } else {
                        return alert('ไม่สามารถบันทึกสินค้าที่มีจำนวนคงเหลือติดลบได้');
                    }

                }
            });
            $q.all(update_stock_promise).then(function () {
                edit_product.edit_summary_status = false;
            });
        };

        // reset button
        product.reset_product_stock = function (edit_product) {
            edit_product.edit_summary_status = false;
            angular.forEach(edit_product.items, function (item) {
                item.stock_change = undefined;
            });
        };

    });
