'use strict';

angular.module('app')
    .controller('BlogListController', function (BlogService, $scope) {

        var blog = this;
        blog.modal = {};
        blog.edit = {preview_image: ''};
        blog.filter = {
            'page': 1,
            'query': ''
        };

        $scope.$watch('blog.filter.query', function(){
            blog.get_blog_list();
        });

        blog.get_blog_list = function () {
            BlogService.get_blog_list(blog.filter).then(function success(response) {
                blog.list = response.data;
            });
        };

        blog.get_blog_category_list = function () {
            BlogService.get_blog_category().then(function success(response) {
                blog.category_list = response.data;
            });
        };

        blog.init = function () {
            blog.get_blog_list();
            blog.get_blog_category_list();
        };

        blog.init();

    });
