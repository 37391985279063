'use strict';

angular.module('app')
    .controller('LoginController', function (MemberService, $state, $localStorage, AuthenticationService, $rootScope, ShopInfoService, $q) {

        var login = this;
        $localStorage.token = '';

        login.makeAuthentication = function () {
            AuthenticationService.login(login.username, login.password)
                .then(function successCallback(result) {
                    var token = result.data.token;
                    var parts = token.split('.');
                    var payload = JSON.parse(urlBase64Decode(parts[1]));
                    var user_id = payload['user_id'];
                    $localStorage.token = result.data.token;

                    var init_promise = [];
                    
                    init_promise.push(MemberService.get_member_detail(user_id).then(function(result){
                    	
                    	$localStorage.root_user = result.data;
                        $rootScope.root_user = result.data;
                        $rootScope.root_user['permissions'] = [];

                        if(result.data.groups.length > 0) {
                            angular.forEach(result.data.groups[0]['permissions'], function(permissions){
                                $rootScope.root_user['permissions'].push(permissions['codename']);
                            });
                        }

                    }));

                    init_promise.push(
                        ShopInfoService.get_shop_info().then(function success(response) {
                            $localStorage.shop_info = response.data[0];
                            $rootScope.shop_info = response.data[0];
                        })
                    );

                    $q.all(init_promise).then(function () {
                        console.log($rootScope.root_user);
                        if($rootScope.root_user.groups.length > 0) {
                            if($rootScope.root_user.groups[0]['name'] = "pc"){
                                $state.go('branch.daily_order');
                            } else {
                                $state.go('order.list');
                            }
                        } else {
                            $state.go('order.list');
                        }
                    });
                    

                }, function errorCallback(response) {
                    alert('กรุณาตรวจสอบ Username, Password');
                });

        };

    });
