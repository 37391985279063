'use strict';

angular.module('app')
    .controller('PageListController', function (PageService, $scope) {

        var page = this;
        page.filter = {
            'page': 1,
            'query': ''
        };

        page.get_page_list = function () {
            PageService.get_list().then(function success(response) {
                page.list = response.data;
            });
        };

        page.init = function () {
            page.get_page_list();
        };

        page.init();

    });
