'use strict';

angular.module('app')
    .factory('TemplateService', function ($http, APIURL, RefreshToken, $filter) {

        var template = this;

        template.get_html_path = function () {
            return $http({
                url: APIURL + '/shopinfo/get_html_path/',
                method: 'GET'
            });
        };

        template.get_source = function (path) {
            var fd = new FormData();
            fd.append('path', path);

            return $http.post(APIURL + '/shopinfo/get_source/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        template.save_source = function (path, source) {
            var fd = new FormData();
            fd.append('path', path);
            fd.append('source', source);

            return $http.post(APIURL + '/shopinfo/save_source/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };


        template.get_custom_css = function () {
            return $http({
                url: APIURL + '/shopinfo/get_custom_css/',
                method: 'GET'
            });
        };

        template.save_custom_css = function (source) {
            var fd = new FormData();
            fd.append('source', source);

            return $http.post(APIURL + '/shopinfo/save_custom_css/', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        return template;
    });
