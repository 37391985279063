function config($translateProvider) {

    $translateProvider
        .translations('en', {

            // Define all menu elements
            DASHBOARD: 'Dashboard',
            GRAPHS: 'Graphs',
            MAILBOX: 'Mailbox',
            WIDGETS: 'Widgets',
            METRICS: 'Metrics',
            FORMS: 'Forms',
            APPVIEWS: 'App views',
            OTHERPAGES: 'Other pages',
            UIELEMENTS: 'UI elements',
            MISCELLANEOUS: 'Miscellaneous',
            GRIDOPTIONS: 'Grid options',
            TABLES: 'Tables',
            COMMERCE: 'E-commerce',
            GALLERY: 'Gallery',
            MENULEVELS: 'Menu levels',
            ANIMATIONS: 'Animations',
            LANDING: 'Landing page',
            LAYOUTS: 'Layouts',

            // Define some custom text
            WELCOME: 'Welcome Amelia',
            MESSAGEINFO: 'You have 42 messages and 6 notifications.',
            SEARCH: 'Search for something...',
            DEMO: 'Internationalization (sometimes shortened to \"I18N , meaning \"I - eighteen letters -N\") is the process of planning and implementing products and services so that they can easily be adapted to specific local languages and cultures, a process called localization . The internationalization process is sometimes called translation or localization enablement .',
            Select_Language: 'please select languages'
        })
        .translations('th', {

            // Define all menu elements
            DASHBOARD: 'สรุป',
            GRAPHS: 'กราฟ',
            MAILBOX: 'เมลบ๊อก',
            WIDGETS: 'วิดเจ๊ด',
            METRICS: 'เมททริก',
            FORMS: 'ฟอร์ม',
            APPVIEWS: 'แอปวิว',
            OTHERPAGES: 'หน้าอื่น',
            UIELEMENTS: 'อิลิเม้น',
            MISCELLANEOUS: 'อื่น',
            GRIDOPTIONS: 'กริต',
            TABLES: 'ตาราง',
            COMMERCE: 'ซื้อขาย',
            GALLERY: 'รูปภาพ',
            MENULEVELS: 'เมนู',
            ANIMATIONS: 'อนิเมชัน',
            LANDING: 'หน้าแลนดิ้ง',
            LAYOUTS: 'เลเอาท์',

            // Define some custom text
            WELCOME: 'ยินดีต้อนรับ',
            MESSAGEINFO: 'คุณมีข้อความส่งถึง',
            SEARCH: 'ค้นหา คำสั่งซื้อ/ลูกค้า/สินค้า',
            DEMO: 'เดโม',
            Select_Language: 'กรุณาเลือกภาษา',
            PRODUCT_MANAGEMENT: 'จัดการสินค้า',
            PRODUCT_LIST: 'รายการสินค้าทั้งหมด',
            PRODUCT_ADD: 'เพิ่มสินค้า',
            PRODUCT_DETAIL: 'รายละเอียดสินค้า',
            PRODUCT_CATEGORY: 'คอลเลกชัน',
            PRODUCT_NAME: 'ชื่อสินค้า',
            PRODUCT_DESCRIPTION: 'รายละเอียดสินค้า',
            PRODUCT_PRICE: 'ราคาเต็ม',
            PRODUCT_SALE_PRICE: 'ราคาขาย',
            CLICK_FOR_MULTIPLE_SELECT_IMAGE_FILE: 'คลิกเพื่ออัปโหลดไฟล์ภาพ',
            READY_FOR_SHIPPING_DATE: 'วันทีพร้อมส่ง',
            NOTE: 'หมายเหตุ',
            PRODUCT_STATUS: 'สถานะ',
            PRODUCT_TAGS: 'แท็กสินค้า',
            PRODUCT_ITEM: 'สต๊อกสินค้าและราคาขายจริง',
            PRODUCT_IMAGE: 'ภาพประกอบสินค้า',
            ADD_PRODUCT_TAG: 'เพิ่มแท็กสินค้า',
            NO_CATEGORY_MATCHING: 'ไม่พบคอลเลกชันสินค้า',
            PLEASE_SELECT_CATEGORY: 'กรุณาเลือกคอลเลกชันสินค้า',
            ADD_NEW_CATEGORY: '+ เพิ่มคอลเลกชันสินค้าใหม่',
            SELECT_COLOR: 'กรุณาเลือกสีสินค้า',
            ORDER_MANAGEMENT: 'คำสั่งซื้อ',
            ORDER_LIST: 'คำสั่งซื้อทั้งหมด',
            ORDER_ADD: 'เพิ่มคำสั่งซื้อ',
            TAG_LIST: 'จัดประเภทสินค้า',
            COLLECTION_LIST: 'คอลเลกชันสินค้าทั้งหมด',
            direct: 'โอนเงิน',
            waiting_payment: 'รอจ่าย',
            payment_review: 'รอเช็กยอด',
            paid: 'จ่ายแล้ว',
            processing: 'รอส่ง',
            canceled: 'ยกเลิก',
            completed: 'ส่งแล้ว',
            SHOP_SETTINGS: 'ตั้งค่าร้านค้า',
            ORDER_STATUS_NUMBER: 'รายการคำสั่งซื้อหมายเลข',
            CONTENT_MANAGEMENT: 'จัดการเนื้อหา',
            PROMOTION: 'โปรโมชัน',
            MEMBERS: 'สมาชิก',
            PRODUCT_SHORT_DESCRIPTION: 'รายละเอียดสินค้าแบบย่อ',
            ORDER_SUMMARY: 'สรุปรายการยอดขาย',
            COLLECTION_MANAGEMENT: 'จัดการคอลเลกชัน',
            collection: 'คอลเลกชัน',
            add_new_collection: 'เพิ่มคอลเลกชันใหม่',
            Home: 'สรุป',
            TAG_MANAGEMENT: 'จัดการแท็ก',
            WEB_ANALYTIC: 'สรุปยอดผู้ใช้งาน',
            All_Collection: 'คอลเลกชันทั้งหมด',
            PROCESS_COLLECTION_COMPLETED: 'ปรับปรุงคอลเลกชันเรียบร้อย',
            TAG: 'แท็ก',
            Add_Tag_Group: 'เพิ่มกลุ่มของแท็ก',
            delete_completed: 'ลบข้อมูลเรียบร้อย',
            save: 'บันทึก',
            TAG_GROUP_EDIT: 'แก้ไขกลุ่มของแท็ก',
            TAG_GROUP_ADD: 'เพิ่มกลุ่มของแท็ก',
            Member: 'สมาชิก',
            All_member: 'รายชื่อสมาชิกทั้งหมด',
            member_search: 'ค้นหาสมาชิกจากชื่อ',
            address: 'ที่อยู่',
            Last_activity: 'กิจกรรมล่าสุดของลูกค้า',
            Last_Login: 'ล๊อคอินล่าสุด',
            Sign_Up_date: 'วันที่สมัคร',
            shipping: 'วิธีการจัดส่ง',
            content_list: 'บทความทั้งหมด',
            content_collection_list: 'กลุ่มบทความ',
            BLOG_CATEGORY_MANAGEMENT: 'จัดการกลุ่มบทความ',
            all_blog_category: 'กลุ่มบทความทั้งหมดในเว็บไซต์',
            add_new_content_collection: 'เพิ่มกลุ่มของบทความ',
            edit_content_collection: 'แก้ไขกลุ่มบทความ',
            PROCESS_blog_COMPLETED: 'ปรับปรุงกลุ่มของบทควาามเรียบร้อย',
            BLOG_ADD: 'เพิ่มบทความ',
            BLOG_EDIT: 'แก้ไขบทความ',
            all_blog: 'แสดงบทความทั้งหมดของเว็บไซต์',
            blog: 'บทความ',
            search_blog_title: 'ค้นหาบทความจากชื่อ',
            edit: 'แก้ไข',
            Active: 'เปิดใช้งาน',
            ORDER_UPDATE_UPLOAD_FILE: 'อัปโหลดไฟล์แก้ไขคำสั่งซื้อ',
            BLOG_MANAGEMENT: 'จัดการบทความในเว็บไซต์',
            BLOG_DETAIL: 'รายละเอียดบทความ',
            BLOG_LIST: 'บทความทั้งหมด',
            web_settings: 'ตั้งค่าการทำงานพื้นฐานสำหรับร้านค้า',
            ABOUT_WEB_SITE: 'ตั้งค่าพื้นฐานของเว็บไซต์',
            STOCK_SYSTEM: 'ระบบคลังสินค้า',
            SHIPPING_ADD: 'เพิ่มวิธีการขนส่ง',
            shipping_settings: 'ตั้งค่าวิธีการจัดส่ง',
            shipping_edit: 'แก้ไขวิธีการขนส่ง',
            shipping_add: 'เพิ่มวิธีการขนส่ง',
            'can not delete': 'ไม่สามารถลบได้',
            promotion_settings: 'ตั้งค่าส่วนลดสินค้าภายในเว็บไซต์',
            PROMOTION_ADD: 'เพิ่มโปรโมชัน',
            'upload order excel file': 'อัปโหลดไฟล์',
            'template editor': 'แก้ไขหน้าตาเว็บไซต์',
            'order status': 'สถานะคำสั่งซื้อ',
            'order detail': 'รายละเอียดคำสั่งซื้อ',
            'order all': 'คำสั่งซื้อทังหมด',
            date: 'วันที่',
            time: 'เวลา',
            page: 'เว็บเพจ',
            block: 'บล็อก',
            coupon: 'คูปอง',
            discount_cart: 'ลดราคา',
            total_order_price: 'เมื่อซื้อครบ',
            total_buy_same_product: 'เมื่อซื้อชิ้นเดียวกัน',
            no_condition: 'ไม่มีเงื่อนไข',
            condition: 'เงื่อนไข',
            type: 'ชนิด',
            title: 'ชื่อ',
            'promotion list': 'โปรโมชันทั้งหมด',
            discount: 'ลดราคา',
            currency_unit: 'บาท',
            piece: 'ชิ้น',
            'total order': 'จำนวนคำสั่งซื้อ',
            'total income': 'รายรับทั้งหมด',
            'total discount': 'เงินที่ลดราคาทั้งหมด',
            'total item': 'สินค้าที่ขายออกไป',
            'promotion add': 'เพิ่มโปรโมชัน',
            'promotion edit': 'แก้ไขโปรโมชัน',
            'discount cart': 'ลดราคา',
            'promotion type': 'ชนิดของโปรโมชัน',
            'promotion title': 'ชื่อโปรโมชัน',
            'promotion description': 'รายละเอียดโปรโมชัน',
            'status': 'สถานะ',
            'coupon code': 'คูปองโค้ด',
            view: 'ดูข้อมูล',
            'promotion condition': 'เงื่อนไขการใช้งานโปรโมชัน',
            'start date': 'วันเริ่มต้น',
            'end date': 'วันสินสุด',
            baht: 'บาท',
            'promotion discount type': 'รับส่วนลดเป็น',
            'percent': 'เปอร์เซ็น',
            'number_of_product': 'จำนวนสินค้า',
            'money': 'เงิน',
            'use_with_product_in_tag': 'ใช้กับสินค้าที่อยู่ในแท็ก',
            'chose customer': 'เลือกลูกค้า',
            'use with all product': 'ใช้กับสินค้าทุกชิ้น',
            'use with some product': 'ใช้กับสินค้าบางชิ้น',
            'some member': 'ลูกค้าบางคน',
            'all member': 'ลูกค้าทั้งหมด',
            'please select product tags': 'กรุณาเลือกแท็กของสินค้า',
            'preview image': 'ภาพประกอบ',
            'can not delete a promotion that has already been used': 'ไม่สามารถลบ โปรโมชัน ที่ใช้ไปแล้วได้',
            'web block management': 'บล็อก',
            'block add and edit': 'จัดการบล็อกภายในเว็บไซต์',
            'add new block group': 'เพิ่มบล็อก',
            'block edit': 'แก้ไขบล็อก',
            'block add': 'เพิ่มบล็อก',
            'description': 'รายละเอียด',
            'cancel': 'ยกเลิก',
            'close': 'ปิด',
            'delete': 'ลบ',
            "can't delete this group, please remove all item in group before delete": 'ไม่สามารถลบกลุ่มนี้ได้ กรุณาลบบล็อกในกลุ่มนี้ก่อน',
            'add block': 'เพิ่มบล็อก',
            'active date': 'วันที่เริ่มทำงาน',
            'link': 'ลิงก์ปลายทาง',
            'save order': 'บันทึกลำดับ',
            'all page': 'แสดงหน้าทั้งหมดที่มีอยู่ในระบบ',
            'page add': 'เพิ่มหน้าเว็บ',
            'page detail': 'รายละเอียดเว็บเพจ',
            'short description': 'รายละเอียดแบบย่อ',
            'content': 'เนื้อหา',
            'payment': 'การชำระเงิน',
            'payment direct': 'โอนเงิน',
            'Bank': 'ธนาคาร',
            'bank list for direct payment': 'รายชื่อธนาคารในระบบทั้งหมด สำหรับการชำระเงินแบบโอนเงิน',
            'bank add': 'เพิ่มธนาคาร',
            'account number': 'เลขบัญชี',
            'account owner': 'ชื่อเจ้าของบัญชี',
            'account branch': 'สาขา',
            'bank name': 'ชื่อธนาคาร',
            'logo': 'โลโก้',
            'Paypal Config': 'PAYPAL',
            'config paypal': 'ตั้งค่าการชำระเงินด้วยระบบ paypal',
            'Active Users': 'จำนวนผู้ใช้ขณะนี้',
            'Now': 'ขณะนี้',
            'Visitors': 'จำนวนผู้ใช้',
            'Monthly': 'เดือนนี้',
            'save and still': 'บันทึกและยังอยู่หน้าเดิม',
            'time for use per user': 'จำนวนครั้งที่ใช้ได้ต่อคน',
            'SHOP_INFO_SETTINGS': 'ตั้งค่าระดับสูง',
            'COLOR': 'สี',
            'All_Color': 'สีทั้งหมด',
            'COLOR_MANAGEMENT': 'จัดการสีของสินค้า',
            'color': 'สี'

        });

    $translateProvider.preferredLanguage('th');

}

angular
    .module('app')
    .config(config);
