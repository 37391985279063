'use strict';

angular.module('app')
    .controller('TemplateEditController', function (TemplateService, $scope) {

        var template = this;
        template.file_data = {folders: []};
        template.index = 1;


        template.get_html = function () {
            TemplateService.get_html_path().then(function success(response) {
                angular.forEach(response.data, function (value, key) {
                    template.file_data['folders'].push({'name': key});
                    template.file_data['folders'][0]['files'] = [];
                    template._create_file_data(template.file_data['folders'][0], value);
                });
            });
        };

        template._create_file_data = function (primary_folder, in_folder) {
            var folder_index = 0;
            primary_folder['folders'] = [];

            angular.forEach(in_folder, function (value, key) {
                if (key == 'files') {
                    angular.forEach(value, function (filename) {
                        primary_folder['files'].push({name: filename});
                    });
                } else {
                    primary_folder['folders'].push({name: key});
                    primary_folder['folders'][folder_index]['files'] = [];
                    template._create_file_data(primary_folder['folders'][folder_index], value);
                    folder_index += 1;
                }
            });
        };

        template.options = {
            collapsible: false,
            onNodeSelect: function (node, breadcrums) {
                $scope.breadcrums = breadcrums;
                template.edit_file_name = breadcrums.join('/');
                TemplateService.get_source(breadcrums.join('/')).then(function success(response) {
                    template.source = response.data.source;
                });
            }
        };

        template.editorOptions = {
            lineNumbers: true,
            theme: 'monokai',
            lineWrapping: true,
            indentWithTabs: true
        };

        template.cancel_edit = function() {
            template.edit_file_name = '';
        };

        template.save_edit = function(){
            template.save_process = true;
            if(template.edit_file_name != 'custom-css.css') {
                TemplateService.save_source(template.edit_file_name, template.source).then(function success(response) {
                    template.save_process = false;
                    template.save_result = response.data.result;
                });
            } else if(template.edit_file_name == 'custom-css.css'){
                TemplateService.save_custom_css(template.source).then(function success(response) {
                    template.save_process = false;
                    template.save_result = response.data.result;
                });
            }
        };

        template.init = function () {
            template.get_html();
        };

        template.init();


        // custom css
        template.custom_css = function () {
            console.log('custom');
            TemplateService.get_custom_css().then(function success(response) {
                console.log(response);
                template.edit_file_name = 'custom-css.css';
                template.source =response.data.result;
            });
        }

    });
