'use strict';

angular.module('app')
    .factory('MemberService', function ($http, APIURL, RefreshToken, $filter) {

        var member = this;
        var limit = 10;
        var params = {};

        member.get_member_list = function (filter) {
            var page = filter.page || 1;
            params.limit = limit;
            params.offset = limit*(page-1);

            return $http({
                url: APIURL + '/account/list/',
                method: 'GET',
                params: params
            });
        };

        member.get_member_search = function (filter) {
            var page = filter.page || 1;
            params.limit = limit;
            params.offset = limit*(page-1);
            params.query = filter.query;

            return $http({
                url: APIURL + '/account/list/search/',
                method: 'GET',
                params: params
            });
        };

        member.get_member_detail = function (id) {
            return $http({
                url: APIURL + '/account/list/' + id + '/',
                method: 'GET'
            });
        };

        member.get_all_member = function() {
            return $http({
                url: APIURL + '/account/list/'
            });
        };

        member.get_order = function(filter) {
            var page = filter.page || 1;
            params.limit = limit;
            params.offset = limit*(page-1);
            params.customer_id = filter.customer_id;

            return $http({
                url: APIURL + '/checkout/order/',
                method: 'GET',
                params: params
            });
        };

        member.get_total = function(customer_id) {
            return $http({
                url: APIURL + '/checkout/order/count_total/?customer_id=' + customer_id
            });
        };

        member.admin_login = function(customer_id) {
            return $http({
                url: APIURL + '/account/list/login_as?customer_id='+customer_id
            });
        };

        return member;
    });
