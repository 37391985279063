'use strict';

angular.module('app')
    .controller('PaypalConfigController', function (PaypalPaymentService, notify, $scope) {

        var paypal = this;

        paypal.init = function () {
            PaypalPaymentService.get_config().then(function success(response){
                paypal.edit = response.data[0];
            });
        };

        paypal.set_preview_image = function (element) {
            var reader = new FileReader();
            reader.onload = function (event) {
                paypal.edit.shop_logo = event.target.result;
                $scope.$apply()
            };
            reader.readAsDataURL(element.files[0]);
        };

        paypal.save = function() {
            paypal.is_updating = true;
            if(paypal.paypal_image){
                paypal.edit.image = paypal.paypal_image;
            }
            if(paypal.edit.id){
                // update
                PaypalPaymentService.update_config(paypal.edit).then(function success(response){
                    paypal.is_updating = false;
                }, function error(response) {
                    paypal.is_updating = false;
                    notify({
                        message: response.data,
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                });
            } else {
                // add
                PaypalPaymentService.add_config(paypal.edit).then(function success(response){
                    paypal.is_updating = false;
                }, function error(response) {
                    paypal.is_updating = false;
                    notify({
                        message: response.data,
                        classes: 'alert-info',
                        templateUrl: 'app/common/notify.html'
                    });
                });
            }
        };

        paypal.init();

    });
