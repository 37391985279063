// WebAnalyticsController
'use strict';

angular.module('app')
    .controller('WebAnalyticsController', function (ShopInfoService, $scope, $rootScope, $timeout, $http, $interval) {

        if ($rootScope.dashboard_restart) {
            location.reload();
        }

        var analytics = this;

        analytics.init = function () {
            ShopInfoService.get_google_token().then(function success(response) {
                var analytics_view_id = response.data.analytics_view;
                var token = response.data.token;
                analytics.render_chart(analytics_view_id, token);

                analytics.analytics_view_id = analytics_view_id;
                analytics.token = token;


                /* ------------- connect google analytics api ---------------- */
                analytics.count = 0;
                analytics.time_counter = function () {
                    analytics.count = analytics.count + 1;
                };
                analytics.time_counter();

                analytics.active_user = 0;
                analytics.get_active_user = function () {
                    var real_time = 'https://www.googleapis.com/analytics/v3/data/realtime?ids=' + analytics_view_id + '&metrics=rt:activeUsers&access_token=' + token;
                    $http.get(real_time).then(function success(response) {
                        analytics.count = 0;
                        analytics.active_user = response.data.rows[0][0];
                        console.log('get active user');
                    });
                };
                analytics.get_active_user();

                var promise = $interval(analytics.get_active_user, 30000);
                var promise_counter = $interval(analytics.time_counter, 1000);
                $scope.$on('$destroy', function () {
                    if (angular.isDefined(promise)) {
                        $interval.cancel(promise);
                        promise = undefined;
                    }
                    if (angular.isDefined(promise_counter)) {
                        $interval.cancel(promise_counter);
                        promise_counter = undefined;
                    }
                });


                analytics.get_session = function () {
                    var session_request_url = 'https://www.googleapis.com/analytics/v3/data/ga?ids=' + analytics_view_id + '&start-date=30daysAgo&end-date=yesterday&metrics=ga%3Asessions&access_token=' + token;

                    var session_request_url_last_month = 'https://www.googleapis.com/analytics/v3/data/ga?ids=' + analytics_view_id + '&start-date=60daysAgo&end-date=30daysAgo&metrics=ga%3Asessions&access_token=' + token;

                    $http.get(session_request_url).then(function success(response) {
                        analytics.session = response.data.rows[0][0];
                    });

                    $http.get(session_request_url_last_month).then(function success(response) {
                        analytics.session_last_month = response.data.rows[0][0];
                    });

                };
                $timeout(function () {
                    analytics.get_session();
                }, 100);

                analytics.get_bounce_rate = function () {
                    var session_request_url = 'https://www.googleapis.com/analytics/v3/data/ga?ids=' + analytics_view_id + '&start-date=30daysAgo&end-date=yesterday&metrics=ga%3AbounceRate&access_token=' + token;

                    var session_request_url_last_month = 'https://www.googleapis.com/analytics/v3/data/ga?ids=' + analytics_view_id + '&start-date=60daysAgo&end-date=30daysAgo&metrics=ga%3AbounceRate&access_token=' + token;

                    $http.get(session_request_url).then(function success(response) {
                        analytics.bounce_rate = response.data.rows[0][0];
                    });

                    $http.get(session_request_url_last_month).then(function success(response) {
                        analytics.bounce_rate_last_month = response.data.rows[0][0];
                    });

                };
                $timeout(function () {
                    analytics.get_bounce_rate();
                }, 200);


            });
        };

        analytics.render_chart = function (analytics_view_id, token) {

            gapi.analytics.ready(function () {
                $rootScope.dashboard_restart = true;

                gapi.analytics.auth.authorize({
                    'serverAuth': {
                        'access_token': token
                    }
                });

                var userSessionChart = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        'ids': analytics_view_id, // <-- Replace with the ids value for your view.
                        'start-date': '90daysAgo',
                        'end-date': 'yesterday',
                        'metrics': 'ga:sessions,ga:users',
                        'dimensions': 'ga:date'
                    },
                    chart: {
                        'container': 'user-session-chart',
                        'type': 'LINE',
                        'options': {
                            'width': '100%'
                        }
                    }
                });
                userSessionChart.execute();


                var viewPath = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        ids: analytics_view_id,
                        metrics: 'ga:pageviews',
                        dimensions: 'ga:pagePath',
                        'start-date': '30daysAgo',
                        'end-date': 'yesterday',
                        'max-results': 10,
                        'sort': '-ga:pageviews'
                    },
                    chart: {
                        container: 'table-container-page-views-path',
                        type: 'TABLE',
                        options: {
                            width: '100%'
                        }
                    }
                });
                $timeout(function () {
                    viewPath.execute();
                }, 1000);

                var sessionSource = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        ids: analytics_view_id,
                        metrics: 'ga:sessions',
                        dimensions: 'ga:source',
                        'start-date': '30daysAgo',
                        'end-date': 'yesterday',
                        'max-results': 10,
                        'sort': '-ga:sessions'
                    },
                    chart: {
                        container: 'table-container-session-source',
                        type: 'TABLE',
                        options: {
                            width: '100%'
                        }
                    }
                });
                $timeout(function () {
                    sessionSource.execute();
                }, 1200);

                var sessionCountry = new gapi.analytics.googleCharts.DataChart({
                    reportType: 'ga',
                    query: {
                        ids: analytics_view_id,
                        metrics: 'ga:sessions',
                        dimensions: 'ga:region',
                        'start-date': '30daysAgo',
                        'end-date': 'yesterday',
                        'sort': '-ga:sessions',
                        'max-results': 10
                    },
                    chart: {
                        container: 'table-container-session-country',
                        type: 'TABLE',
                        options: {
                            width: '100%'
                        }
                    }
                });
                $timeout(function () {
                    sessionCountry.execute();
                }, 1300);

                var userType = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        ids: analytics_view_id,
                        metrics: 'ga:users',
                        dimensions: 'ga:userType',
                        'start-date': '30daysAgo',
                        'end-date': 'yesterday'
                    },
                    chart: {
                        container: 'chart-container-user-user-type',
                        type: 'PIE',
                        options: {
                            width: '100%',
                            pieHole: 0.4
                        }
                    }
                });
                $timeout(function () {
                    userType.execute();
                }, 2000);

                var userTypeTable = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        ids: analytics_view_id,
                        metrics: 'ga:users',
                        dimensions: 'ga:userType',
                        'start-date': '30daysAgo',
                        'end-date': 'yesterday'
                    },
                    chart: {
                        container: 'table-container-user-user-type',
                        type: 'TABLE',
                        options: {
                            width: '100%',
                            pieHole: 0.4
                        }
                    }
                });
                $timeout(function () {
                    userTypeTable.execute();
                }, 2220);

                var userAge = new gapi.analytics.googleCharts.DataChart({
                    reportType: 'ga',
                    query: {
                        ids: analytics_view_id,
                        metrics: 'ga:users',
                        dimensions: 'ga:userAgeBracket',
                        'start-date': '30daysAgo',
                        'end-date': 'yesterday'
                    },
                    chart: {
                        container: 'chart-container-user-age-type',
                        type: 'COLUMN',
                        options: {
                            width: '100%',
                            pieHole: 0.4
                        }
                    }
                });
                $timeout(function () {
                    userAge.execute();
                }, 2400);

                var userAgeTable = new gapi.analytics.googleCharts.DataChart({
                    reportType: 'ga',
                    query: {
                        ids: analytics_view_id,
                        metrics: 'ga:users',
                        dimensions: 'ga:userAgeBracket',
                        'start-date': '30daysAgo',
                        'end-date': 'yesterday'
                    },
                    chart: {
                        container: 'table-container-user-age-type',
                        type: 'TABLE',
                        options: {
                            width: '100%',
                            pieHole: 0.4
                        }
                    }
                });
                $timeout(function () {
                    userAgeTable.execute();
                }, 2600);

                var userGender = new gapi.analytics.googleCharts.DataChart({
                    reportType: 'ga',
                    query: {
                        ids: analytics_view_id,
                        metrics: 'ga:users',
                        dimensions: 'ga:userGender',
                        'start-date': '30daysAgo',
                        'end-date': 'yesterday'
                    },
                    chart: {
                        container: 'chart-container-user-gender',
                        type: 'PIE',
                        options: {
                            width: '100%',
                            pieHole: 0.4
                        }
                    }
                });
                $timeout(function () {
                    userGender.execute();
                }, 2800);

                var userGenderTable = new gapi.analytics.googleCharts.DataChart({
                    reportType: 'ga',
                    query: {
                        ids: analytics_view_id,
                        metrics: 'ga:users',
                        dimensions: 'ga:userGender',
                        'start-date': '30daysAgo',
                        'end-date': 'yesterday'
                    },
                    chart: {
                        container: 'table-container-user-gender',
                        type: 'TABLE',
                        options: {
                            width: '100%'
                        }
                    }
                });
                $timeout(function () {
                    userGenderTable.execute();
                }, 3000);

                var userDevice = new gapi.analytics.googleCharts.DataChart({
                    reportType: 'ga',
                    query: {
                        ids: analytics_view_id,
                        metrics: 'ga:sessions',
                        dimensions: 'ga:deviceCategory',
                        'start-date': '30daysAgo',
                        'end-date': 'yesterday'
                    },
                    chart: {
                        container: 'chart-container-session-device-category',
                        type: 'PIE',
                        options: {
                            width: '100%',
                            pieHole: 0.4
                        }
                    }
                });
                $timeout(function () {
                    userDevice.execute();
                }, 3200);

                var userDeviceTable = new gapi.analytics.googleCharts.DataChart({
                    reportType: 'ga',
                    query: {
                        ids: analytics_view_id,
                        metrics: 'ga:sessions',
                        dimensions: 'ga:deviceCategory',
                        'start-date': '30daysAgo',
                        'end-date': 'yesterday'
                    },
                    chart: {
                        container: 'table-container-session-device-category',
                        type: 'TABLE',
                        options: {
                            width: '100%'
                        }
                    }
                });
                $timeout(function () {
                    userDeviceTable.execute();
                }, 3400);

                var mobileBrand = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        ids: analytics_view_id,
                        metrics: 'ga:sessions',
                        dimensions: 'ga:mobileDeviceBranding',
                        'start-date': '30daysAgo',
                        'end-date': 'yesterday',
                        'max-results': 5,
                        'sort': '-ga:sessions'
                    },
                    chart: {
                        container: 'chart-container-session-mobile-device-branding',
                        type: 'PIE',
                        options: {
                            width: '100%',
                            pieHole: 0.4
                        }
                    }
                });
                $timeout(function () {
                    mobileBrand.execute();
                }, 4000);

                var mobileBrandTable = new gapi.analytics.googleCharts.DataChart({
                    reportType: 'ga',
                    query: {
                        ids: analytics_view_id,
                        metrics: 'ga:sessions',
                        dimensions: 'ga:mobileDeviceBranding',
                        'start-date': '30daysAgo',
                        'end-date': 'yesterday',
                        'max-results': 5,
                        'sort': '-ga:sessions'
                    },
                    chart: {
                        container: 'table-container-session-mobile-device-branding',
                        type: 'TABLE',
                        options: {
                            width: '100%',
                            pieHole: 0.4
                        }
                    }
                });
                $timeout(function () {
                    mobileBrandTable.execute();
                }, 4200);


                var userScreen = new gapi.analytics.googleCharts.DataChart({
                    query: {
                        ids: analytics_view_id,
                        metrics: 'ga:users',
                        dimensions: 'ga:screenResolution',
                        'start-date': '30daysAgo',
                        'end-date': 'yesterday',
                        'max-results': 10,
                        'sort': '-ga:users'
                    },
                    chart: {
                        container: 'chart-container-user-screen',
                        type: 'PIE',
                        options: {
                            width: '100%',
                            pieHole: 0.4
                        }
                    }
                });
                $timeout(function () {
                    userScreen.execute();
                }, 4400);

                var userScreenTable = new gapi.analytics.googleCharts.DataChart({
                    reportType: 'ga',
                    query: {
                        ids: analytics_view_id,
                        metrics: 'ga:users',
                        dimensions: 'ga:screenResolution',
                        'start-date': '30daysAgo',
                        'end-date': 'yesterday',
                        'max-results': 10,
                        'sort': '-ga:users'
                    },
                    chart: {
                        container: 'table-container-user-screen',
                        type: 'TABLE',
                        options: {
                            width: '100%',
                            pieHole: 0.4
                        }
                    }
                });
                $timeout(function () {
                    userScreenTable.execute();
                }, 4600);

            });
        };

        analytics.init();

        return analytics;
    });

