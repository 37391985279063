'use strict';

angular.module('app')
    .controller('OrderDetailController', function (OrderService, $stateParams) {
        var order = this;
	    order.id = $stateParams.id;
	    
	    
	    order.get_order_status_change_log = function(){
	        OrderService.get_order_status_change_log(order.id).then(function success(response){
	        	order.status_change_logs = response.data;
	        });
	    };
	    
	    order.get_order_detail = function() {
	    	OrderService.get_order_detail(order.id).then(function success(response){
	            order.detail = response.data;
		    })
	    };
	    
	    order.init = function(){
			order.get_order_status_change_log();
			order.get_order_detail();
	    };
	    
	    order.init();

    });
