'use strict';

angular.module('app')
    .controller('MemberListController', function (MemberService, $scope, $window, $stateParams, APIURL, Upload, $q, $state) {

        var member = this;
        member.login_link = false;
        member.filter = { page: 1, query: '' };
        member.order_filter = { page: 1 };
        $scope.$watchGroup(['member.filter.page'], function () {
            member.get_member_list();
        });

        $scope.$watchGroup(['member.filter.query'], function () {
            if(member.filter.query){
                    MemberService.get_member_search(member.filter).then(function (response) {
                    member.list = response.data;
                    member.login_link = false;
                });
            } else {
                member.get_member_list();
            }
        });

        member.get_member_list = function () {
            MemberService.get_member_list(member.filter).then(function (response) {
                member.list = response.data;
                member.login_link = false;
            });
        };

        member.get_more_detail = function (member_detail) {
            member.login_link = false;
            member.order_list = {};
            member.detail = member_detail;
            console.log(member.detail);
            member.order_filter.customer_id = member_detail.id;
            MemberService.get_order(member.order_filter).then(function success(response){
                member.order_list = response.data;
            });
            MemberService.get_total(member.order_filter.customer_id).then(function success(response){
                member.total_order_price = response.data.total_price__sum;
            });
        };

        member.order_change_page = function () {
            MemberService.get_order(member.order_filter).then(function success(response){
                member.order_list = response.data;
            });
        };

        member.login = function (member_detail) {
            MemberService.admin_login(member_detail.id).then(function success(response){
                // $window.open(response.data.link, '_blank');
                member.login_link = response.data.link;
            });

        };

        member.init = function () {
            member.get_member_list();
        };

        member.init();


    });
