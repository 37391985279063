'use strict';

angular.module('app')
    .controller('BranchCommissionController', function (BranchService, $scope) {
        var order = this;
        order.filter = {page: 1, pc__user: ''};
        order.pc = {list: {}};

        if(!$scope.root_user.is_superuser) {
            order.filter.pc__user = $scope.root_user.id;
        }

        order.get_commission = function() {
            BranchService.get_commission(order.filter).then(function (response){
                order.list = response.data;
                order.updating = false;
            });
        };

        BranchService.get_pc().then(function success(response){
            order.pc.list = response.data;
        });

        $scope.$watchGroup(['order_c.filter.page'], function () {
            order.updating = true;
            order.get_commission();
        });

        $scope.$watchGroup(['order_c.filter.start_date', 'order_c.filter.end_date', 'order_c.filter.page', 'order_c.filter.pc'], function () {
            order.updating = true;
            order.get_commission();
        });

        order.export_excel = function() {
            order.is_loading_excel_file = true;
            BranchService.get_commission_excel(order.filter).then(function success(response){
                console.log(response);
                var blob = new Blob([response.data], { type: "application/xls" });
                saveAs(blob, 'commission.xls');
                order.is_loading_excel_file = false;
            });
        };

    });